import { Badge, Box, Link } from "@material-ui/core";
import React from "react";
import {
  INotification,
  useNotificationListController,
} from "./NotificationList.controller";
import Accordion from "modus/components/misc/Accordion";
import MemberRequestIcon from "legacy-components/icons/MemberRequestIcon";
import { AsyncActionState } from "modus/components/form/async-actions/AsyncAction.type";
import Loader from "modus/components/misc/Loader";
import {
  ErrorMessage,
  resolveAPIErrorMessage,
} from "modus/components/error-message/ErrorMessage";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import NotificationActions from "pages/home/notifications/components/NotificationActions/TeamNotificationActions";

export const MemberNotificationList: React.FC = () => {
  const { asyncResponse } = useNotificationListController({ type: "TEAM" });
  const { open, toggle } = useDialogProps(true);
  return (
    <Box>
      <Accordion
        icon={<MemberRequestIcon color="primary" />}
        title={"Member Request"}
        expanded={open}
        onClick={toggle}
        className="notification-accordion"
        details={<MemberNotificationListResponse response={asyncResponse} />}
      />
    </Box>
  );
};

interface MemberNotificationListResponse {
  response?: AsyncActionState<INotification[] | null | undefined>;
}
export const MemberNotificationListResponse: React.FC<
  MemberNotificationListResponse
> = ({ response }) => {
  return (
    <Box style={{ width: "calc(100% - 30px)" }}>
      {response?.loading && <Loader />}
      {response?.error && (
        <ErrorMessage message={resolveAPIErrorMessage(response.error)} />
      )}

      {response?.loaded && response?.value && response?.value?.length === 0 && (
        <ErrorMessage message={"No pending approvals"} />
      )}
      {response?.loaded &&
        response?.value &&
        response?.value?.length > 0 &&
        response?.value?.map((notification: INotification) => (
          <TeamNotificationCard notification={notification} />
        ))}
    </Box>
  );
};
interface TeamNotificationCardProps {
  notification: INotification;
}
export const TeamNotificationCard: React.FC<TeamNotificationCardProps> = ({
  notification,
}) => {
  const currentTeam = useSelector(
    (state: any) =>
      state?.teamManager?.teams?.currentTeam?.teamName ??
      sessionStorage.getItem(MY_SELECTED_TEAM_)
  );
  const history = useHistory();
  const elapsedTime = getElapsedTime(notification.requestDate);
  return (
    <Box className="notification-card">
      <MemberRequestIcon color="primary" />
      <Box width="100%">
        <Box>
          <Link
            href={`mailto:${notification?.requestedBy?.emailAddress}`}
            target="_blank"
          >
            {notification?.requestedBy?.emailAddress}{" "}
          </Link>
          {notification?.requestedBy?.accountName && (
            <span>
              from{" "}
              <b>
                <i>{notification?.requestedBy?.accountName}</i>
              </b>
            </span>
          )}
          &nbsp;wants to join your team&nbsp;
          <Link onClick={() => history.push(`/home/teams/${currentTeam}`)}>
            {currentTeam}
          </Link>
          .
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Badge className={`badge ${getColor(elapsedTime)}`}>
              {getByText(elapsedTime)}
            </Badge>
            <NotificationActions notification={notification} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export function getByText(
  elapsedTime: { unit: string; count: number } | undefined
): string {
  if (!elapsedTime) {
    return "";
  }
  if(elapsedTime.count === 0) {
    return "Just now submitted";
  }
  if (elapsedTime.unit === "M") {
    return `${elapsedTime.count} minutes ago`;
  }
  if (elapsedTime.unit === "H") {
    return `${elapsedTime.count} hours ago`;
  }
  return `${elapsedTime.count} days ago`;
}

export function getColor(
  elapsedTime: { unit: string; count: number } | undefined
): string {
  if (!elapsedTime) {
    return "";
  }
  if (elapsedTime.unit === "H") {
    return "green";
  }
  // elapsedTime.unit is Days

  if (elapsedTime.count <= 3) {
    return "success";
  } else if (elapsedTime.count <= 7) {
    return "warning";
  } else {
    return "error";
  }
}

export function getElapsedTime(
  dateString?: string
): { unit: string; count: number } | undefined {
  if (!dateString) {
    return;
  }
  const inputDate: Date = new Date(dateString);
  const now: Date = new Date();
  const elapsedTime: number = now.getTime() - inputDate.getTime();

  const millisecondsInAnHour: number = 1000 * 60 * 60;
  const millisecondsInADay: number = millisecondsInAnHour * 24;
  const millisecondsInAMinute: number = 1000 * 60;

  if (elapsedTime < millisecondsInAnHour) {
    const hours: number = Math.floor(elapsedTime / millisecondsInAMinute);
    return { unit: "M", count: hours };
  } else if (elapsedTime < millisecondsInADay) {
    const hours: number = Math.floor(elapsedTime / millisecondsInAnHour);
    return { unit: "H", count: hours };
  } else {
    const days: number = Math.floor(elapsedTime / millisecondsInADay);
    return { unit: "D", count: days };
  }
}
