import { Paper } from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren, Ref, RefObject } from "react";

import { makeStyles, createStyles } from "@material-ui/core";
import React from "react";

interface TableContainerProps  {
    ref?: any;
}


export const useGridStyles = makeStyles(theme => {
    return createStyles({
        root: {
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            "&.ag-theme-alpine": {
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.primary,

                "& .ag-root-wrapper": {
                    border: "none"
                },

                "& .ag-header": {
                    backgroundColor: "#EAEAEF",
                    borderColor: theme.palette.divider,

                    "& .ag-header-row": {
                        color: theme.palette.text.primary,                        
                        fontWeight: theme.typography.fontWeightMedium
                    }
                },

                "& .ag-header-cell": {
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3)
                },

                "& .ag-pinned-right-header": {
                    border: "none",

                    "& .ag-header-row": {
                        "&::after": {
                            display: "none"
                        }
                    }
                },

                "& .ag-row": {
                    border: "none"
                },

                "& .ag-cell": {
                    border: "none!important",
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    lineHeight: "42px"
                },

                "& .form-selection-grid__action-cell": {
                    padding: theme.spacing(0, 2, 0, 0),
                    textAlign: "right"
                },

                "& .ag-cell .ag-react-container": {
                    width: '100%',
                    height: '100%',
                }
            }
        },
        header: {
            "&--tinted": {
                "&.ag-theme-alpine": {
                    "& .ag-header": {
                        backgroundColor: theme.palette.background.default,
                    }
                }
            }
        },
        clickable: {
            "&.ag-theme-alpine": {
                "& .ag-row": {
                    cursor: "pointer",
                    transition: theme.transitions.create("backgroundColor"),

                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },

                    "&:active": {
                        backgroundColor: theme.palette.action.selected,
                    }
                },

            }
        },
        deleteButton: {
            marginLeft : "20px"
        }
    })
});

export const TableContainer = ({ children, ref }: PropsWithChildren<TableContainerProps>) => {
    const gridStyle = useGridStyles();

    return (
        <Paper ref={ref} elevation={2} className={clsx("ag-theme-alpine", gridStyle.root, `${gridStyle.header}--tinted`, gridStyle.clickable)}>
            {children}
        </Paper>
    )
}