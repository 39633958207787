import { Box, Typography } from "@material-ui/core";
import React from "react";
import { toDate } from "./helpers";

export const Date: React.FC<any> = ({ formattedValue, value }) => {
    return (
    <Box display={"flex"}  alignItems={"center"} height="100%">
      <Typography variant="body1" style={{color: '#464B52', fontWeight: 300, fontSize: '14px'}}>
        {formattedValue ? (toDate(formattedValue)) : (value ? toDate(value) : ' - ')}
      </Typography>
      </Box>
    );
};

export const Email: React.FC<{ valueFormatted?: string; value?: string }> =
React.memo(({ valueFormatted, value }) => {
  return (
    <Box display={"flex"}  alignItems={"center"} height="100%">
      {(valueFormatted || value) && (
        <a href={`mailto:${valueFormatted ?? value}`} className="grid-title">
        <Typography variant="body1" color="primary" style={{fontSize: "14px"}}>
          {valueFormatted ?? value}
        </Typography>
      </a>
      )}
      {
        (!valueFormatted && !value) && (
          <Typography variant="body1" style={{fontSize: "12px", fontWeight:300}}>
            User not Available
          </Typography>
        )
      }
    </Box>
    
  );
});

export const Title: React.FC<{ valueFormatted?: string; value?: string }> =
  React.memo(({ valueFormatted, value }) => {
    return (
      <span className="grid-title">
        <Typography variant="h5" color="primary">
          {valueFormatted ?? value}
        </Typography>
      </span>
    );
  });

  export const SubTitle: React.FC<{ valueFormatted?: string; value?: string }> =
  React.memo(({ valueFormatted, value }) => {
    return (
      <span className="grid-title">
        <Typography variant="body1" color="primary">
          {valueFormatted ?? value}
        </Typography>
      </span>
    );
  });