import { Box } from "@material-ui/core";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import React from "react";
import { TeamMemberList } from "../components/member-list/MemberListViewer";
import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import { useSelector } from "react-redux";
import { MemberAssociator } from "../components/member-associator/MemberAssociator";
import { useMemberListController } from "../components/member-list/MemberListController";
import { Breadcrumbs } from "modus/components/generic-page-title/Breadcrumbs";

export const TeamSummaryPage = () => {

    const {state, subscribe} = useMemberListController();

    const onChange = () => {
        subscribe();
    }
    const currentTeam = useSelector((state: any) => state.teamManager.teams.currentTeam);
    return (
        <Box padding="20px" className="team-discovery-page">
            <Breadcrumbs 
                links={[{text: "Teams"}]}
                pageTitle={sessionStorage.getItem(MY_SELECTED_TEAM_) as string ?? currentTeam?.teamName ??  undefined} />
            <GenericPageTitle
                title={sessionStorage.getItem(MY_SELECTED_TEAM_) as string ?? currentTeam?.teamName ??  "My Team"}
                description={currentTeam?.description}
                noSkeleton
                controls={<Box display={"flex"}>
                    <MemberAssociator onSuccess={onChange}/>
                    </Box>}
            />
            <Box mt="30px"/>
            <TeamMemberList state={state} refresh={subscribe} />
        </Box>
    );
}