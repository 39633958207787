import React, { useCallback, useState, useContext, FC, useEffect } from "react";
import {
  Dialog,
  Paper,
  Tooltip,
  IconButton,
  FormLabel,
  TextField,
  Box,
  MenuItem,
  Button,
  DialogTitle,
} from "@material-ui/core";

import { useCompactDialog } from "legacy-components/dialog/UseCompactDialog";
import Select from "legacy-components/form/container/Select";
import Add from "@material-ui/icons/Add";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import TeamService from "../../services/TeamService";
import { DEFAULT_IDENTITY_ENVIRONMENT } from "header/environment-selector/ENV_CONSTANTS";
import { useSelector } from "react-redux";
import Submit from "modus/components/form/submit/Submit";
import { Alert } from "@material-ui/lab";
import { resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import { RegexPatterns } from "legacy-components/form/validator/Validator";
import clsx from "clsx";
interface MemberAssociatorProps {
  onSuccess?: () => void;
}
export const MemberAssociator: FC<MemberAssociatorProps> = ({
  onSuccess
}) => {
    const { open, setOpen} = useCompactDialog();
    const closeDialog = () => setOpen(false);
    const openDialog = () => setOpen(true);
    const teamName  = useSelector((state: any) => state?.teamManager?.teams?.currentTeam?.teamName ??  sessionStorage.getItem(MY_SELECTED_TEAM_));
    return (
        <>
<Tooltip title={"Add New Member"}>
        <span>
          <Button onClick={openDialog} variant="contained" color="primary" startIcon={<Add/>}>
            Add New Member
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={closeDialog}
      >
       
          <Paper>
            <UserInviteForm onClose={closeDialog} teamName={teamName} onSuccess={onSuccess}/>
          </Paper>
      </Dialog>
      </>
    );
}

interface UserInviteFormProps {
  onClose: () => void;
  teamName: string;
  onSuccess?: () => void;
}
export const UserInviteForm:React.FC<UserInviteFormProps> = ({onClose, teamName, onSuccess}) => {
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("Trimble Developer");
    const [userRoles, setRoles] = useState<
    Array<{
      key: string;
      value: string;
    }>
  >([
    {
      key: "TEAM ADMIN",
      value: "Administrator"
    },
    {
      key: "Trimble Developer",
      value: "User"
    },
  ]);
  const isValid = () => {
    if(email === "" || role === "") {
      return false;
    } 
    if(email && !email.match(RegexPatterns.GenericEmail)) {
      return false;
    }
    return true;
  }
const gateway = DEFAULT_IDENTITY_ENVIRONMENT ;
  const {state, subscribe: associateMember} = useAsyncAction(()=>  TeamService.inviteUser(gateway, {
    "name": email,
    "emailAddress": email,
    "role": role,
    "team": teamName
}));

useEffect(() => {
  
  if(state.loaded && !state?.error) {
    onSuccess && onSuccess();
    setTimeout(() => {
      onClose();
    }, 3000);
  }
},[state.loaded, state.error, onSuccess, onClose]);

    return (
        <Box m={2}>
            <DialogTitle>Add New Member</DialogTitle>

            {(state.loaded && !state.error) && (
              <Box m={2}>
                  <Alert severity="success">Invitation send to the user</Alert>
              </Box>
            )}
            {state.error && (
              <Box m={2}>
                  <Alert severity="error">{resolveAPIErrorMessage(state.error)}</Alert>
              </Box>
            )}
            <Box m={2}>
              <Box>
                <FormLabel className="ModusFormTextInput--label">Email <span className="required">*</span></FormLabel>
                <TextField
                    className={clsx("ModusFormTextInput--root", {"email-error-field": email && !email.match(RegexPatterns.GenericEmail)})}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter the Email address"
                    onChange={(e) => setEmail(e.target.value)}
                    />
                  { email && !email.match(RegexPatterns.GenericEmail) && (<div className="email-error" >Provide a valid Email Address</div>)}
            </Box>
            <Box my={2}>
                <FormLabel className="ModusFormTextInput--label">Role <span className="required">*</span></FormLabel>
                <Select
                  className="ModusFormTextInput--root"
                    id="user-role"
                    placeHolder="Select the Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth={true}
                    
                >
                  {userRoles.map((role: any) => (
                    <MenuItem value={role.key} key={role.key}>
                      {role.value}
                    </MenuItem>
                  ))}
                </Select>
            </Box>
            </Box>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} m={3} mr={2} pt={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Box paddingLeft={"15px"} />
              <Submit
                variant="contained"
                color="primary"
                disabled={!isValid()}
                 onClick={() => associateMember()}
                 state={state as any}
              >
                Add Member
              </Submit>
            </Box>
        </Box>
    );
}