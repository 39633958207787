import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { ResourceAbility } from '../../../libs/security/authorization'
import { GenericPageHeaderSkeleton } from '../../../legacy-components/page-headers/GenericPageHeader';
import IdentityView from 'libs/resources/identity/applications/IdentityView';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'themes/mui';
import ApplicationSummaryPage from './pages/application-summary-page/ApplicationSummaryPage';
import { DEFAULT_IDENTITY_ENVIRONMENT } from 'header/environment-selector/ENV_CONSTANTS';

const IdentityApplicationCreate = lazy(() => import('../../../libs/resources/identity/applications/IdentityApplicationCreate'));
const IdentityApplicationList = lazy(() => import('../../../libs/resources/identity/applications/IdentityApplicationList'));
const IdentityApplicationView = lazy(() => import('../../../libs/resources/identity/applications/IdentityApplicationView'));


interface IdentityApplicationRouteProps {

}

const fallPermission = <div className="message info">
    <FormattedMessage defaultMessage="You do not have permission to access the page. Please contact your Team owner." />
</div>

function IdentityApplicationRoute(props: IdentityApplicationRouteProps) {
    const canShowNewAppSummary =
    process.env.REACT_APP_NEW_APPLICATION_SUMMARY === "true";
    return (
        <ThemeProvider theme={theme}>
        <Suspense fallback={<GenericPageHeaderSkeleton />}>
            <Switch>
                <Route exact path="/home/applications" render={() => (<Redirect to="/home/applications/list" />)} />

                <Route path="/home/applications/list" render={() => (
                    <ResourceAbility can="read" resource={({ Application }) => [
                        Application.list.environment[DEFAULT_IDENTITY_ENVIRONMENT],
                         Application.listByUser.environment[DEFAULT_IDENTITY_ENVIRONMENT]]}
                        fallBack={fallPermission} >
                        <IdentityView />
                    </ResourceAbility>
                )} />

                <Route path="/home/applications/create" render={() => (
                    <ResourceAbility can="read" resource={({ Application }) => [
                        Application.create.environment[DEFAULT_IDENTITY_ENVIRONMENT],
                    ]} fallBack={fallPermission} >
                        <IdentityApplicationCreate />
                    </ResourceAbility>
                )} />

                <Route exact path="/home/applications/:applicationName/gateway/:environmentName/summary" render={() => (
                    <ResourceAbility can="read" resource={({ Application }) => [
                        Application.list.environment[DEFAULT_IDENTITY_ENVIRONMENT],
                        Application.listByUser.environment[DEFAULT_IDENTITY_ENVIRONMENT]]}
                        fallBack={fallPermission} >
                        {canShowNewAppSummary ? ( <ApplicationSummaryPage />) : (<IdentityApplicationView />)}
                    </ResourceAbility>
                )} />
            </Switch>
        </Suspense>
        </ThemeProvider>
    )
}

IdentityApplicationRoute.propTypes = {

}

export default IdentityApplicationRoute

