import { Observable } from "rxjs";
import { APIResource, APIMethod } from "../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../utils/http/client/HttpClient";
import { apiMethodContext } from "../../utils/http/builder/UrlConfig";

export interface RolePermission {
    id?: string
    email?: string;
    team?: string;
    teamDisplayName?: string;
    teamId: string;
    uuid: string;
    approved?: string;
    role?: string;
    menus?: any,
    active?: boolean;
    division?: string;
    sector?: string;
    features?: Array<Feature>,
    resources?: {
        [key: string]: string;
    },
    sharedRoles?: [
        {
            rolename?: string,
            shared?: boolean,
            resources?: {
                [key: string]: string;
            }
        }
    ]
}

export interface Feature {
    name: string
}

@APIResource(CloudConsoleClient, "/authorizer/user")
class RoleServiceFactory {

    @APIMethod([], {
        options: {
            noTrailingSlash: true
        }
    })
    public getRole(teamId?: string) {
        const context = apiMethodContext(arguments);

        return new Observable<RolePermission>(subscriber => {
            CloudConsoleClient.get<RolePermission>(context, {
                params: {
                    teamId
                }
            }).then(response => {
                subscriber.next(response.data);
                subscriber.complete();
            }, error => subscriber.error(error));
        })
    }
}

const RoleService = new RoleServiceFactory();

export default RoleService;
