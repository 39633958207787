
import { Box } from "@material-ui/core";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import DataRemover from "modus/components/misc/DataRemover";
import React from "react";
import { useSelector } from "react-redux";
import TeamService from "../../services/TeamService";


interface MemberDissociatorProps {
    userName: string;
    userId: string;
    teamName: string;
    onDeleteSuccess?: () => void;
}

export const MemberDissociator: React.FC<MemberDissociatorProps> = ({ userName, userId, teamName , onDeleteSuccess}) => {
        
    const [state, {subscribe}] = useAsyncAction(() => TeamService.removeUserFromTeam(userId, teamName));
    return (
        <DataRemover
            onConfirm={subscribe}
            asyncActionState={state}
            title="Are you Sure?"
            successMessage={`${userName} is removed from this team`}
            onSuccess={onDeleteSuccess}
            variant="text"
            size="small"
            message={
                <Box>
                    <Box marginTop={"30px"}>
                        Do you want to remove <b>{userName}</b> from this team?
                    </Box>
                </Box>
            }
        />
    )
}