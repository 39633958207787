
import { Box } from "@material-ui/core";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import DataRemover from "modus/components/misc/DataRemover";
import React from "react";
import { useSelector } from "react-redux";
import ApplicationConfigurationService from "../services/ApplicationConfigurationService";
import { Alert } from "@material-ui/lab";


interface ConsumerSecretRemoverProps {
    appName: string;
    secretNumber: string;
    onDeleteSuccess?: () => void;
    disabled?: boolean;
}

export const ConsumerSecretRemover: React.FC<ConsumerSecretRemoverProps> = ({ appName, secretNumber , onDeleteSuccess, disabled}) => {
        
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);
    const [state, {subscribe}] = useAsyncAction(() => ApplicationConfigurationService.deleteSecret(identityEnvironment, secretNumber, appName));
    return (
        <DataRemover
            confirmationText={appName}
            onConfirm={subscribe}
            asyncActionState={state}
            tooltipText={disabled? "Cannot delete the last secret " : "Delete"}
            title="Delete secret key"
            disabled={disabled}
            successMessage="Secret key is removed"
            onSuccess={onDeleteSuccess}
            message={
                <Box>
                    <Alert severity="error">Deleting a secret key will break any current integrations using this key.</Alert>
                    <Box marginTop={"30px"}>
                    This action cannot be undone. This will permanently remove the secret for the application "proxylengthcheck" and cannot be accessed elsewhere.
                    <br/>
                    <br/>
                    Please note that removing this Client Secret will not invalidate any user tokens, however you will need to update any application configuration using this secret because it will no longer work.
                    </Box>
                </Box>
            }
        />
    )
}