import React, { useState, useEffect, useRef, RefObject, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Typography, Paper } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useAsyncAction } from '../../../legacy-components/form/action/AsyncAction';
import clsx from 'clsx';
import { useGridStyles } from '../../../legacy-components/grid/GridStyle';
import { useRole, useResourceAbility } from 'libs/security/authorization/Permission';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import { useHistory, withRouter } from "react-router-dom";

import { useConfirm } from "material-ui-confirm";
import Loader from "../../../legacy-components/loader";
import ProductService from 'libs/resources/product/ProductService';
import { ProductResponse } from './models/products.model';
import { ProductBasicEdit } from './product-forms/ProductBasicEdit';
import ProductDeleteAction from './product-forms/ProductDeleteAction';
import FormAlert from 'legacy-components/form/container/FormAlert';
import { PublishedStatusBadgeCellRenderer } from 'legacy-components/grid-view/GridView';
import { ResourceAbility } from 'libs/security/authorization';
import { sanitizeText } from './product-forms/FileXSSRemover';
import { DEFAULT_IDENTITY_ENVIRONMENT, isNewConsoleSepFlow } from 'header/environment-selector/ENV_CONSTANTS';

enum FrameworkComponentsType {
    LoadingOverlay = "LOADING_OVERLAY",
    NoRowsOverlay = "NO_ROWS_OVERLAY",
    RowData = "ROW_DATA",
    RowActions = "ROW_ACTIONS"
}

type FrameworkComponentState = { [K in FrameworkComponentsType]: (...params: any) => JSX.Element };

interface IGripOverlayPropRef {
    error: boolean;
}

const ProductList = (props: any) => {

    const intl = useIntl();
    const gridStyle = useGridStyles();
    const [, state] = useRole();
    const confirm = useConfirm();

    const [isFormCompleted, setIsFormCompleted] = useState<string>();

    const onSuccess = () => {
        setIsFormCompleted(Math.random().toString());
    }
   
    const LoadingComponent = () => <Loader />;
    const NoRowsComponent = (props: {
        gridOverlayPropRef: RefObject<IGripOverlayPropRef>
    }) => {
        return (
            <Typography variant="body2">
                {props.gridOverlayPropRef?.current?.error ? <FormattedMessage defaultMessage="No Products available" /> : <FormattedMessage defaultMessage="No Products available" />}
            </Typography>
        );
    };

    const DataComponent = (data: any) => {
        return (
            <div>
                {data.name}
            </div>
        );
    }

    const getRowNodeId = (data: {
        id: string
    }) => data.id;

    const RowActionComponent = (props: {
        data: any,

        options: {
            onDeleteHandler: (data: any) => () => void
            onEditHandler: (data: any) => () => void
        }
    }) => {
        const compliance = [];
        if (props.data.iso) {
            compliance.push("iso")
        }
        if (props.data.soc2) {
            compliance.push("soc2");
        }
        if (props.data.soc3) {
            compliance.push("soc3");
        }
        if (props.data.tsdcl) {
            compliance.push("tsdcl");
        }

        const product = {
            displayName: sanitizeText(props.data.displayName),
            description: sanitizeText(props.data.description),
            visibility: sanitizeText(props.data.visibility),
            compliance: compliance,
            apiTypeMapping: props.data.apiTypeMapping == null ? [] : props.data.apiTypeMapping

        }

        return (
            <ResourceAbility can="create" resource={({ Product }) => Product.create}>
                <>
                    <ProductBasicEdit productName={props.data.name} product={product} onSuccess={onSuccess}/>
                    <ProductDeleteAction productName={props.data.name} isProxiesMapped = {props.data.isProxiesMapped} hasSubscriptions = {props.data.hasSubscriptions} onDeleteTriggered={showDeleteMessage}></ProductDeleteAction>
               </>
            </ResourceAbility>
        );
    };

    const [frameworkComponents] = useState<FrameworkComponentState>({
        [FrameworkComponentsType.LoadingOverlay]: LoadingComponent,
        [FrameworkComponentsType.NoRowsOverlay]: NoRowsComponent,
        [FrameworkComponentsType.RowData]: DataComponent,
        [FrameworkComponentsType.RowActions]: RowActionComponent
    });

    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    let [collection, { subscribe }] = useAsyncAction<any[]>(() => ProductService.getList());

    let history = useHistory();

    type alertType = "success" | "error" | "warning" | undefined;
    const [apiMessage, setApiMessage] = useState<string | undefined>();
    const [apiMessageType, setApiMessageType] = useState<alertType>();

    const onDelete = useCallback((data: any) => {
        return new Promise((resolve, reject) => {
            UnifiedAPIService.delete(
                UnifiedAPI.parseRequestURL(UnifiedAPI.delete_product_api, {
                    productName: data.name
                }), {} //Params to replace in URL
            ).then(
                response => {
                    resolve(response.data);
                    setApiMessage(data.name + " Product Deleted")
                    setApiMessageType("success");
                    setTimeout(() => {
                        history.go(0);
                    }, 2000);
                },
                error => {
                    setApiMessage(error.response?.data?.message);
                    setApiMessageType("error");
                    reject(error?.message ?? "Unknown error occured while deleting");
                }
            );
        })
    }, [history]);
    
    const [can,  {loaded, intermediate}] = useResourceAbility({
        resource: ({ TeamMembers }) => TeamMembers.invite,
    });

    const columnDefs = useRef<ColDef[]>([]);
  
    const gridOverlayPropRef = useRef<IGripOverlayPropRef>({
        error: false
    });
    
    function capitalize(s: string) {
        return s[0].toUpperCase() + s.slice(1);
    }
    
    useEffect(() => {
        if (gridApi) {
            gridOverlayPropRef.current.error = collection.error;

            if (collection.error) {
                gridApi.setRowData([]);
            } else if (collection.value) {
                gridApi.setRowData(collection.value);
            }           
        }
    }, [collection, gridApi]);
    
    useEffect(() => {
        if (loaded || intermediate) {
        
            columnDefs.current = [];
            columnDefs?.current.push({
                    field: "name",
                    sortable: true,
                    unSortIcon : true,
                    filter: "agTextColumnFilter",
                    filterParams: {
                        suppressAndOrCondition: true,
                        defaultOption: 'contains',
                        filterOptions: ['contains']
                    },
                    comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
                    headerName: intl.formatMessage({
                        defaultMessage: "Name"
                    }),
                    flex: 1
                }, {
                    field: "displayName",
                    sortable: true,
                    unSortIcon : true,
                    filter: "agTextColumnFilter",
                    filterParams: {
                        suppressAndOrCondition: true,
                        defaultOption: 'contains',
                        filterOptions: ['contains']
                    },
                    comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
                    headerName: intl.formatMessage({
                        defaultMessage: "Display Name"
                    }),
                    flex: 1
                }, {
                    field: "publishedGateway",
                    headerName: intl.formatMessage({
                        defaultMessage: "Publish Status"
                    }),
                    filterParams: {
                        suppressAndOrCondition: true,
                        defaultOption: 'contains',
                        filterOptions: ['contains']
                    },
                    flex: 0.9,
                    type: 'rightAligned',
                    cellRendererFramework: PublishedStatusBadgeCellRenderer,
                    valueGetter: (params) => {
                        let env = [];

                        if (params.data.publishedGateway.length == 0) {
                            env.push("not-published");
                        } else {
                            params.data.publishedGateway.map((item: any) => {
                                if(item.status === "SUCCEEDED" ) {
                                    if (!isNewConsoleSepFlow) {
                                        env.push(item.gatewayName);
                                    }  else if (isNewConsoleSepFlow && item.gatewayName?.toLowerCase() === DEFAULT_IDENTITY_ENVIRONMENT) {
                                        env.push("published");
                                    } 
                                }
                            });
                        }
                        if (isNewConsoleSepFlow)  {
                            if (env.length == 0) {
                                env.push("not-published");
                            }
                        }

                        return env;
                    }
                }, {
                    field: "created",
                    sortable: true,
                    unSortIcon : true,
                    headerName: intl.formatMessage({
                        defaultMessage: "Created Date"
                    }),
                    flex: 1
                }, {
                    field: "visibility",
                    sortable: false,
                    unSortIcon : true,
                    filter: "agTextColumnFilter",
                    filterParams: {
                        suppressAndOrCondition: true,
                        defaultOption: 'contains',
                        filterOptions: ['contains']
                    },
                    comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
                    headerName: intl.formatMessage({
                        defaultMessage: "Visibility"
                    }),
                    flex: 0.65,
                    valueGetter: (params) => {
                        return capitalize(params.data.visibility)
                    }
                });
                if(can?.create()) {
                    columnDefs?.current.push({
                        field: "createdBy",
                        sortable: true,
                        unSortIcon : true,
                        headerName: intl.formatMessage({
                            defaultMessage: "Created By"
                        }),
                        flex: 1,
                        resizable: true
                    })
                }
                columnDefs?.current.push({
                    field: "actions",
                    headerName: "",
                    flex: 0.5,
                    type: 'rightAligned',
                    cellRenderer: FrameworkComponentsType.RowActions,
                    cellRendererParams: {
                        options: {
                            onDeleteHandler: (data: any) => {
                            return (event: MouseEvent) => {
                                event.preventDefault();
                                confirm(
                                    {
                                        description: 'Do you want to delete ' + data.name + '?'
                                    }
                                ).then(
                                    () => {
        
                                        onDelete(data);
                                });
                            }
                        },
                        onEditHandler: (data: ProductResponse) => {
                            return (event: MouseEvent) => {
                                event.preventDefault();
                                props.onEditClick(data.name);
            
                            }
                        }
                    }
                }
            })
        }
    }, [intermediate, loaded, can.create()]);

    useEffect(() => {
        let unsubscribe = () => {

        }
        if (state.loaded || state.intermediate) {
            unsubscribe = subscribe()
        }
        return () => unsubscribe();
    }, [isFormCompleted, subscribe, state.loaded || state.intermediate]);

    const onGridReady = (event: GridReadyEvent) => setGridApi(event.api);
    const onCellClicked = useCallback((event: any) => {
        event.colDef.field !== 'actions' && history.push(`/home/products/${event.data.name}/summary`);
    }, [history]);

    const showDeleteMessage = (response: any) => {
        showMessage(response.message, response.messageType);
    }

    const showMessage = useCallback((message: string, type?: string) => {
        if (!type) {
            type = "success";
        }
        if (message) {
            setApiMessage(message);
            setApiMessageType(type as ("success" | "error"));
            setTimeout(() => {
                setIsFormCompleted(Math.random().toString());
                hideMessage(null);
            }, 4000);
        }
    }, [setApiMessageType, setApiMessage]);

    const hideMessage = useCallback((event) => {
        event?.preventDefault();
        event?.stopPropagation();
        setApiMessage("");
        setApiMessageType(undefined);
    }, [setApiMessageType, setApiMessage]);

    return (
        <>
            <Paper elevation={2} className={clsx("ag-theme-alpine", gridStyle.root, `${gridStyle.header}--tinted`, gridStyle.clickable)}>

                {apiMessageType && (<FormAlert severity={apiMessageType as "success" | "error" | "info"}>{apiMessage}</FormAlert>)}
                {collection &&
                    <AgGridReact
                        columnDefs={columnDefs?.current}
                        frameworkComponents={frameworkComponents}
                        loadingOverlayComponent={FrameworkComponentsType.LoadingOverlay}
                        noRowsOverlayComponent={FrameworkComponentsType.NoRowsOverlay}
                        noRowsOverlayComponentParams={{ gridOverlayPropRef }}
                        suppressCellSelection={true}
                        suppressRowHoverHighlight={true}
                        getRowNodeId={getRowNodeId}
                        undoRedoCellEditing={true}
                        onGridReady={onGridReady}
                        onCellClicked={onCellClicked}
                    />
                }
            </Paper>
        </>
    )
}

export default withRouter(ProductList);

