import { FieldValidator, FieldState } from "final-form";
import React from "react";
import { mapToArray } from "../../../libs/utils/shared/common";

interface PatternInput {
    regExp: RegExp,
    seperator?: string
}

const maxLength = (maxVal: number) => (value: any) =>
    value && value.length > maxVal ? `Must be ${maxVal} characters or less` : undefined

const minLength = (minVal: number) => (value: any) =>
    value && value.length < minVal ? `Must be ${minVal} characters or more` : undefined

const min = (minVal: number) => (value: any) =>
    value && value < minVal ? `Must be greater than ${minVal} ` : undefined

const max = (maxVal: number) => (value: any) =>
    value && value > maxVal ? `Must be lesser than ${maxVal} ` : undefined

const pattern = (input: RegExp | PatternInput) => (value: any) => {
    if (!(input instanceof RegExp)) {
        const regex = input.regExp;

        if (typeof value === "string") {
            const values = mapToArray(input.seperator ? value.split(input.seperator) : value);

            if (values.map(item => String(item).trim()).filter(item => item.length > 0).every(item => regex.test(item))) {
                return;
            }
        }
    } else if (input.test(value)) {
        return;
    }

    if (!value) {
        return;
    }

    return `Invalid input`;
}


function _required(value: any) {
    if (Array.isArray(value)) {
        if (value.length > 0) {
            return;
        }

    } else if (value || typeof value === 'number') {
        return;
    }

    return 'Required';
}

function _minLength(length: number) {
    return minLength(length);
}

function _maxLength(length: number) {
    return maxLength(length);
}

function _min(minimum: number) {
    return min(minimum);
}

function _max(maximum: number) {
    return max(maximum);
}

function _pattern(regex: RegExp | PatternInput) {
    return pattern(regex);
}

function _custom(customFn: () => (value: any) => string | undefined | void) {
    return customFn();
}

export const RegexPatterns = {
    HttpUrl: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^?]*)?(\?([a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)(&[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*)?$/,
    GenericEmail: /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9\.-]+(?:\.[a-zA-Z0-9-]+){1,}$/,
    GenericResourceName: /^[a-zA-Z0-9-_]+$/,
    GenericDisplayName: /^[a-zA-Z0-9-_()[\]]+( [a-zA-Z0-9-_()[\]]+)*$/,
    Numeric: /^[0-9]*$/,
    Percentage: /^(?:(\d{1,2}|\d{1})(\.\d{1,2})*?%)$/,
    Version: /^v([0-9]+)$/
};

export const parseInteger = (value: string) => {
    try {
        return parseInt(value)
    }
    catch(error) {
        return 0
    }

}

interface ValidatorInputOptions {
    required?: boolean,
    minLength?: number,
    maxLength?: number,
    custom?: () => (value: any) => string | undefined | void | Promise<string | undefined | void>
    min?: number,
    max?: number,
    pattern?: (value: typeof RegexPatterns) => RegExp | PatternInput | RegExp
};


class ValidatorBuilder {
    private readonly validators: Map<string, {
        fn: FieldValidator<any>
    }> = new Map();


    public with(options: ValidatorInputOptions) {
        const _options: any = options;
        Object.keys(options ?? {}).forEach(key => {
            const self: any = this;

            if (self[key]) {
                self[key](_options[key]);
            }
        });

        return this;
    }

    public from(options: ValidatorInputOptions) {
        return this.with(options).build();
    }

    public build(): (value: any, allValue: any, meta?: FieldState<any>) => FieldValidator<any> | undefined {
        return (value, allValue, meta) => Array.from(this.validators.values()).reduce((error, val) => error || val.fn(value, allValue, meta), undefined);
    }

    public required() {
        this.validators.set("_required", {
            fn: _required
        });
        return this;
    }

    public min(length: number) {
        if (!(length === null || isNaN(length))) {
            this.validators.set("_min", {
                fn: _min(length)
            });
        }
        return this;
    }
    public max(length: number) {
        if (!(length === null || isNaN(length))) {
            this.validators.set("_max", {
                fn: _max(length)
            });
        }
        return this;
    }
    public minLength(length: number) {
        if (!(length === null || isNaN(length))) {
            this.validators.set("_minLength", {
                fn: _minLength(length)
            });
        }
        return this;
    }

    public maxLength(length: number) {
        if (!(length === null || isNaN(length))) {
            this.validators.set("_maxLength", {
                fn: _maxLength(length)
            });
        }

        return this;
    }

    public pattern(selector: (value: typeof RegexPatterns) => RegExp) {
        if (typeof selector === "function") {
            this.validators.set("_pattern", {
                fn: _pattern(selector(RegexPatterns))
            });
        }

        return this;
    }

    public custom(customFn: () => (value: any) => string | undefined | void) {
        if (typeof customFn === "function") {
            this.validators.set("_custom", {
                fn: _custom(customFn)
            });
        }

        return this;
    }
}

class ValidatorBuilderRef {
    get validator() {
        return new ValidatorBuilder();
    }

    public from(options: ValidatorInputOptions) {
        return this.validator.from(options);
    }
}

export const builder = new ValidatorBuilderRef();

export const ValidatorBuilderContext = React.createContext(builder);

export default builder;
