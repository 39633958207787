import { AsyncActionState } from "../../../legacy-components/form/action/AsyncAction";
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import RoleService, { RolePermission } from "./RoleService";
import { ReplaySubject } from "rxjs";
import { useAsyncAction } from "../../../legacy-components/form/action/AsyncAction";
import { IdentityApplicationEnvironmentType } from "../../resources/identity/applications/PublisherApplicationService";
import { mapToArray } from "../../utils/shared/common";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import UnifiedAPIService from "services/UnifiedAPIService";
import TeamService from "libs/resources/team/TeamService";
import ConsumerClient, {
  ConsumerClient2,
} from "pages/home/consumer/service/ConsumerClient";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/actions";

export type ResourceAbiltiyAction = "read" | "create" | "delete" | "update";

abstract class Ability {
  abstract can: {
    [key: string]: () => boolean;
  };

  abstract check(value: string): boolean;
}

class ResourceAbility extends Ability {
  public static defaultPermission = "GET/POST/UPDATE/DELETE";

  private static permissionMap: {
    [K in ResourceAbiltiyAction]: string[];
  } = {
    read: ["GET"],
    create: ["POST"],
    delete: ["DELETE"],
    update: ["PUT"],
  };

  public can = {
    read: () => this.check("read"),
    create: () => this.check("create"),
    delete: () => this.check("delete"),
    update: () => this.check("update"),
  };

  constructor(
    public readonly resources: string[],
    private readonly permissions?: Array<string | undefined>,
    public valid?: boolean
  ) {
    super();
  }

  public check(action: ResourceAbiltiyAction): boolean {
    const permissions = ResourceAbility.permissionMap[action];

    return permissions.some(
      (value) =>
        this.permissions?.some((permission) => permission?.includes(value)) ??
        false
    );
  }
}

class RolePermissionFactory {
  rolePermission$ = new ReplaySubject<RolePermission>(1);
  rolePermissionMap = new Map<string, RolePermission>();

  resolvePermissions(teamId?: string) {
    RoleService.getRole(teamId).subscribe((value) => {
      CloudConsoleClient.useTeam?.(value?.teamId);
      UnifiedAPIService.useTeam?.(value?.teamId);
      TeamService.useTeam?.(value?.teamId);
      ConsumerClient.useTeam?.(value?.teamId);
      ConsumerClient2.useTeam?.(value?.teamId);
      this.rolePermissionMap.set(value.teamId, value);
      this.rolePermission$.next(value);
    });
  }

  resolveRoleFor(teamId?: string) {
    if (teamId && this.rolePermissionMap.has(teamId)) {
      CloudConsoleClient.useTeam?.(teamId);
      UnifiedAPIService.useTeam?.(teamId);
      TeamService.useTeam?.(teamId);
      ConsumerClient.useTeam?.(teamId);
      ConsumerClient2.useTeam?.(teamId);
      this.rolePermission$.next(this.rolePermissionMap.get(teamId));
    } else {
      this.resolvePermissions(teamId);
    }
  }
}

export const RolePermissionContext = createContext<RolePermissionFactory>(
  new RolePermissionFactory()
);

export function useAuthResolver(): [
  RolePermission | null,
  AsyncActionState<RolePermission | null>,
  (teamId?: string) => void
] {
  const permissionContext = useContext(RolePermissionContext);

  const [state, { subscribe }] = useAsyncAction(
    () => permissionContext.rolePermission$
  );

  useEffect(() => {
    // permissionContext.resolveRoleFor();

    const unsubscribe = subscribe(true);

    return () => unsubscribe();
  }, [permissionContext, subscribe]);

  const changeTeam = useCallback(
    (teamId?: string) => {
      permissionContext.resolveRoleFor(teamId);
    },
    [permissionContext]
  );

  return [
    state.value as RolePermission | null,
    state as AsyncActionState<RolePermission | null>,
    changeTeam,
  ];
}

const ResourceCapabilites: {
  TeamMembers: {
    list: string;
    create: string;
    invite: string;
    delete: string;
  };
  Team: {
    list: string;
    delete: string;
  };
  Documentation: string;
  Product: {
    list: string;
    get?: string;
    create: string;
    publish: {
      environment: any;
    };
    update?: string;
    delete?: string;
    mapping: string;
  };
  Application: {
    list: {
      environment: any;
    };
    listByUser: {
      environment: any;
    };
    create: {
      environment: {
        [key in IdentityApplicationEnvironmentType]: string;
      };
    };
  };
  Proxy: {
    list: string;
    get?: string;
    create: string;
    update?: string;
    delete?: string;
    share: string;
    org: {
      environment: any;
    };
    deploy: {
      environments: any;
      environment: any;
    };
    verification: {
      getStarted: string;
    };
  };
  Certificate: {
    list: {
      environment: any;
    };
    create: {
      environment: any;
    };
    update: {
      environment: any;
    };
    delete: {
      environment: any;
    };
  };
} = {
  TeamMembers: {
    create: "/publisher/team",
    list: "/publisher/team/[^/]+/users",
    invite: "/publisher/user",
    delete: "/publisher/user/[^/]+",
  },
  Team: {
    list: "/publisher/sector/[^/]+/teams",
    delete: "/publisher/team/[^/]+",
  },
  Documentation: "/publisher/documentation/file",
  Product: {
    create: "/publisher/product",
    list: "/publisher/product",
    mapping: "/publisher/product/[^/]+/mapping",
    publish: {
      environment: {
        "trimble-pre-prod":
          "/publisher/product/[^/]+/gateway/trimble-pre-prod/publish",
        "trimble-prod": "/publisher/product/[^/]+/gateway/trimble-prod/publish",
      },
    },
  },
  Application: {
    list: {
      environment: {
        "trimble-pre-prod": "/publisher/applications/gateway/trimble-pre-prod",
        "trimble-prod": "/publisher/applications/gateway/trimble-prod",
      },
    },
    listByUser: {
      environment: {
        "trimble-pre-prod":
          "/publisher/applications/gateway/trimble-pre-prod/user",
        "trimble-prod": "/publisher/applications/gateway/trimble-prod/user",
      },
    },
    create: {
      environment: {
        "trimble-pre-prod":
          "/publisher/applications/[^/]+/gateway/trimble-pre-prod",
        "trimble-prod": "/publisher/applications/[^/]+/gateway/trimble-prod",
      },
    },
  },
  Proxy: {
    list: "/publisher/api",
    create: "/publisher/api/[^/]+/create",
    update: "/publisher/api/[^/]+/update",
    share: "/publisher/api/[^/]+/user/[^/]+/access/EDITOR/share",
    org: {
      environment: {
        "trimble-pre-prod": "/publisher/api/[^/]+/environment/dev",
        "trimble-prod": "/publisher/api/[^/]+/environment/prod",
      },
    },
    deploy: {
      environments: {
        dev: "/publisher/api/[^/]+/environment/dev/deploy",
        qa: "/publisher/api/[^/]+/environment/qa/deploy",
        stage: "/publisher/api/[^/]+/environment/stage/deploy",
        prod: "/publisher/api/[^/]+/environment/prod/deploy",
        "eu-west": "/publisher/api/[^/]+/environment/eu-west/deploy",
      },
      environment: {
        "trimble-pre-prod": "/publisher/api/[^/]+/environment/dev/deploy",
        "trimble-prod": "/publisher/api/[^/]+/environment/prod/deploy",
      },
    },
    verification: {
      getStarted: "/publisher/api/[^/]+/verification/get-started",
    },
  },
  Certificate: {
    list: {
      environment: {
        "trimble-pre-prod":
          "/publisher/truststores/gateway/trimble-pre-prod/certs",
        "trimble-prod": "/publisher/truststores/gateway/trimble-prod/certs",
      },
    },
    create: {
      environment: {
        dev: "/publisher/truststores/environments/dev/upload",
        qa: "/publisher/truststores/environments/qa/upload",
        stage: "/publisher/truststores/environments/stage/upload",
        prod: "/publisher/truststores/environments/prod/upload",
        euwest: "/publisher/truststores/environments/eu-west/upload",
      },
    },
    update: {
      environment: {
        dev: "/publisher/truststores/environments/dev/upload",
        qa: "/publisher/truststores/environments/qa/upload",
        stage: "/publisher/truststores/environments/stage/upload",
        prod: "/publisher/truststores/environments/prod/upload",
        euwest: "/publisher/truststores/environments/eu-west/upload",
      },
    },
    delete: {
      environment: {
        dev: "/publisher/truststores/environments/dev/certs/[^/]+",
        qa: "/publisher/truststores/environments/qa/certs/[^/]+",
        stage: "/publisher/truststores/environments/stage/certs/[^/]+",
        prod: "/publisher/truststores/environments/prod/certs/[^/]+",
        euwest: "/publisher/truststores/environments/eu-west/certs/[^/]+",
      },
    },
  },
};

export const ActionCapabilites = {
  ShareAPI: "shareApi",
};

export interface ResourceAbilityProps {
  resource: (capability: typeof ResourceCapabilites) => string | string[];
  role?: string;
}

export function useResourceAbility(
  props: ResourceAbilityProps
): [
  ResourceAbility["can"],
  AsyncActionState<RolePermission | null>,
  ResourceAbility
] {
  const [resources] = useState(mapToArray(props.resource(ResourceCapabilites)));

  const [rolePermission, state] = useAuthResolver();
  const [ability, setAbility] = useState(new ResourceAbility(resources));

  useEffect(() => {
    if (state.loaded || state.intermediate) {
      if (props.role) {
        const permissions = rolePermission?.sharedRoles?.find(
          (item) => item.rolename?.toLowerCase() === props.role?.toLowerCase()
        );

        setAbility(
          new ResourceAbility(
            resources,
            resources.map((resource) => permissions?.resources?.[resource]),
            true
          )
        );
      } else {
        setAbility(
          new ResourceAbility(
            resources,
            resources.map((resource) => rolePermission?.resources?.[resource]),
            true
          )
        );
      }
    }
  }, [rolePermission, resources, state.loaded || state.intermediate]);

  return [ability.can, state, ability];
}

export function useRolePermissions(): [
  string | undefined,
  AsyncActionState<RolePermission | null>
] {
  const [rolePermission, state] = useAuthResolver();
  return [rolePermission?.role, state];
}

export function useRole(): [
  RolePermission | null,
  AsyncActionState<RolePermission | null>,
  (team: string | undefined) => void
] {
  const [rolePermission, state, changeTeam] = useAuthResolver();
  const dispatch = useDispatch();

  useEffect(() => {
    if (rolePermission && dispatch) {
      dispatch({
        type: actions.storeUserRole,
        payload: rolePermission,
      });
    }
  }, [rolePermission, dispatch]);
  return [rolePermission, state, changeTeam];
}

export interface FeatureAbilityProps {
  id: string;
  role?: string;
}

export function useFeatureAbility(props: FeatureAbilityProps): boolean {
  const [rolePermission, state] = useAuthResolver();
  const [ability, setAbility] = useState(false);

  useEffect(() => {
    if (state.loaded || state.intermediate) {
      if (props.id) {
        setAbility(false);
        rolePermission?.features?.map((item) => {
          if (item.name === props.id.toString()) setAbility(true);
        });
      } else {
        setAbility(false);
      }
    }
  }, [rolePermission, state.loaded || state.intermediate]);

  return ability;
}
