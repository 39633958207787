import {
  APIResource,
  APIMethod,
} from "../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";
import { TeamDetail } from "./models/team.model";
import { sanitize } from "dompurify";

export interface IAPITeam {
  id: string;
  name: string;
  email: string;
  team: string;
  role: string;
}
export interface User {
  id: string;
}
export interface Team {
  id: string;
  teamName: string;
}
export interface IUserTeam {
  user: User;
  team: Team;
}

export interface InviteRequest {
  name?: string;
  emailAddress?: string;
  team?: string;
  role?: string;
}

export interface Context {
  id: string;
  name: string;
  context: string;
}

export interface Namespace {
  apiPublishLimit: number;
  baCode: string;
  contexts: Array<Context>;
  email: string;
  nameSpaces: Array<string>;
  productPublishLimt: number;
  sector: string;
  teamId: string;
  teamName: string;
}

@APIResource(CloudConsoleClient)
class TeamServiceFactory {

  @APIMethod("/publisher/user/teams", {
    options: {
      noTrailingSlash: true,
    },
  })
  public getUsersTeam() {
    return from(
      CloudConsoleClient.get<any>(apiMethodContext(arguments), {
      }).then(
        (response) => response.data
      )
    );
  }

  @APIMethod("/publisher/v2/users/:userUuid/teams", {
    options: {
      noTrailingSlash: true,
    },
  })
  public getUsersTeamByUuid(userUuid: string) {
    return from(
      CloudConsoleClient.get<any>(apiMethodContext(arguments), {
        params:
        {
          userUuid
        }
      }).then(
        (response) => response.data
      )
    );
  }

  @APIMethod("/publisher/v2/users/:uuid/teams/:teamId", {
    options: {
      noTrailingSlash: true,
    },
  })
  public joinUserToTeam(uuid: string, teamId: string) {
    return from(
      CloudConsoleClient.post<any>(apiMethodContext(arguments), {}, {
        params:
        {
          uuid,
          teamId
        }
      }).then(
        (response) => response.data
      ) 
    );
  }

  @APIMethod("/publisher/v2/teams", {
    options: {
      noTrailingSlash: true,
    },
  })
  public getAvailableTeamsForUser() {
    return from(
      CloudConsoleClient.get<any>(apiMethodContext(arguments), {
      }).then(
        (response) => response.data
      )
    );
  }

  @APIMethod("/publisher/v2/teams", {
    options: {
      noTrailingSlash: true,
    },
  })
  public createTeam(teamCreateRequest: any) {
    return from(
      CloudConsoleClient.post<any>(apiMethodContext(arguments), {
        teamCreateRequest
      }).then(
        (response) => response.data
      )
    );
  }

  @APIMethod("/publisher/team/:teamname/users")
  public forTeamUsers(teamname: string) {
    return from(
      CloudConsoleClient.get<IAPITeam[]>(apiMethodContext(arguments), {
        params: {
          teamname,
        },
      }).then((response) => response.data)
    );
  }

  @APIMethod("/publisher/team/:teamname")
  public forTeamUpdate(teamname?: string, teamDetail?: TeamDetail) {
    return from(
      CloudConsoleClient.put<TeamDetail>(
        apiMethodContext(arguments),
        {
          description: sanitize(teamDetail?.description ?? ""),
          website: sanitize(teamDetail?.website??""),
          email: sanitize(teamDetail?.email ?? ""),
          support: sanitize(teamDetail?.support ?? ""),
          division: sanitize(teamDetail?.divisionName ?? ""),
          baCode: sanitize(teamDetail?.baCode ?? ""),
          displayName: sanitize(teamDetail?.displayName?? "")
        },
        {
          params: {
            teamname,
          },
        }
      ).then((response) => response.data)
    );
  }

  @APIMethod("/publisher/team/:teamname")
  public forTeamDetails(teamname: string | undefined) {
    return from(
      CloudConsoleClient.get<TeamDetail>(apiMethodContext(arguments), {
        params: {
          teamname,
        },
      }).then((response) => response.data)
    );
  }

  @APIMethod("/publisher/user/:userId")
  public forDeleteUser(userId: string) {
    return from(
      CloudConsoleClient.delete<void>(apiMethodContext(arguments), {
        params: {
          userId,
        },
      }).then((response) => response.data)
    );
  }

  @APIMethod("/publisher/user")
  public forInviteUser(gateway: string | null, request: InviteRequest) {
    const context = apiMethodContext(arguments);

    return new Promise<void>((resolve, reject) => {
      CloudConsoleClient.post(context, request, {
        params: {
          gateway,
        },
      }).then(
        (response) => resolve(response.data),
        (error) =>
          reject(
            error.response?.data?.message ??
              "Unknown error occured while inviting the user"
          )
      );
    });
  }

  @APIMethod("/publisher/team/:teamName/namespaces")
  public forTeamNamespace(teamName: string | undefined) {
    return from(
      CloudConsoleClient.get<Namespace>(apiMethodContext(arguments), {
        params: {
          teamName,
        },
      }).then((response) => response.data)
    );
  }

  @APIMethod("/publisher/v2/teams")
  public getJoinedTeamsOfUser(filter: string | undefined) {
    return from(
      CloudConsoleClient.get<any>(apiMethodContext(arguments), {
        params: {
          filter,
        },
      }).then((response) => response.data)
    );
  }
}
const TeamServices = new TeamServiceFactory();

export default TeamServices;