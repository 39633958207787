import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  Paper,
  Tooltip,
  IconButton,
  FormLabel,
  TextField,
  Box,
  MenuItem,
  Button,
  DialogTitle,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { useCompactDialog } from "legacy-components/dialog/UseCompactDialog";
import Select from "legacy-components/form/container/Select";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import TeamService from "../../services/TeamService";
import { useSelector } from "react-redux";
import Submit from "modus/components/form/submit/Submit";
import { Alert } from "@material-ui/lab";
import { resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import { DEFAULT_IDENTITY_ENVIRONMENT } from "header/environment-selector/ENV_CONSTANTS";

interface MemberUpdaterProps {
  onSuccess?: () => void;
  user: any;
}
export const MemberUpdater: FC<MemberUpdaterProps> = ({ onSuccess, user }) => {
  const { open, setOpen } = useCompactDialog();
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);
  const teamName = useSelector(
    (state: any) =>
      state?.teamManager?.teams?.currentTeam?.teamName ??
      sessionStorage.getItem(MY_SELECTED_TEAM_)
  );

  const disabled =
    user?.gateways?.filter(
      (gateway: any) =>
        gateway.gatewayName?.toUpperCase() ===
        DEFAULT_IDENTITY_ENVIRONMENT?.toUpperCase()
    )?.[0]?.approval !== "APPROVED";
  return (
    <>
      <Tooltip
        title={
          disabled
            ? "You cannot change the user role until the user is Approved. If you need the user role to get updated immediately, please email to our support"
            : "Change Role"
        }
      >
        <span>
          <IconButton
            onClick={openDialog}
            color="primary"
            style={{ color: "#004F83 ", fill: "#004F83" }}
            size="small"
            disabled={disabled}
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={closeDialog}
      >
        <Paper>
          <UserUpdateForm
            onClose={closeDialog}
            teamName={teamName}
            onSuccess={onSuccess}
            user={user}
          />
        </Paper>
      </Dialog>
    </>
  );
};

interface UserUpdateFormProps {
  onClose: () => void;
  teamName: string;
  onSuccess?: () => void;
  user: any;
}
export const getRefinedRole = (role: string) => {
  if (
    role?.toUpperCase() === "TEAM ADMIN" ||
    role?.toUpperCase() === "SECTOR ADMIN" ||
    role?.toUpperCase() === "SUPERADMIN"
  ) {
    return "TEAM ADMIN";
  } else {
    return "TRIMBLE DEVELOPER";
  }
};
export const UserUpdateForm: React.FC<UserUpdateFormProps> = ({
  onClose,
  teamName,
  onSuccess,
  user,
}) => {
  const [role, setRole] = useState(getRefinedRole(user.role));
  const [userRoles, setRoles] = useState<
    Array<{
      key: string;
      value: string;
    }>
  >([
    {
      key: "TEAM ADMIN",
      value: "Administrator",
    },
    {
      key: "TRIMBLE DEVELOPER",
      value: "User",
    },
  ]);
  const isValid = () => {
    if (role === "") {
      return false;
    }
    if (getRefinedRole(role) === getRefinedRole(user.role)) {
      return false;
    }
    return true;
  };

  const { state, subscribe: associateMember } = useAsyncAction(() =>
    TeamService.updateUser(user.email, role, teamName)
  );

  useEffect(() => {
    if (state.loaded && !state?.error) {
      setTimeout(() => {
        onClose();
        onSuccess && onSuccess();
      }, 3000);
    }
  }, [state.loaded]);

  return (
    <Box m={2}>
      <DialogTitle>Change Role</DialogTitle>

      {state.loaded && !state.error && (
        <Box m={2}>
          <Alert severity="success">Role is updated</Alert>
        </Box>
      )}
      {state.error && (
        <Box m={2}>
          <Alert severity="error">{resolveAPIErrorMessage(state.error)}</Alert>
        </Box>
      )}
      <Box m={2}>
        <Box>
          <FormLabel className="ModusFormTextInput--label">
            Email <span className="required">*</span>
          </FormLabel>
          <TextField
            className="ModusFormTextInput--root"
            fullWidth
            variant="outlined"
            disabled
            value={user.email}
          />
        </Box>
        <Box my={2}>
          <FormLabel className="ModusFormTextInput--label">
            Role <span className="required">*</span>
          </FormLabel>
          <Select
            className="ModusFormTextInput--root"
            id="user-role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            fullWidth={true}
          >
            {userRoles.map((role: any) => (
              <MenuItem value={role.key} key={role.key}>
                {role.value}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        m={3}
        mr={2}
        pt={2}
      >
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Box paddingLeft={"15px"} />
        <Submit
          variant="contained"
          color="primary"
          disabled={!isValid()}
          onClick={() => associateMember()}
          state={state as any}
        >
          Update
        </Submit>
      </Box>
    </Box>
  );
};
