
export const I18N_RESOURCE = {
    FULL_MONTHS : {
        M0 : 'January',
        M1 : 'February',
        M2 : 'March',
        M3 : 'April',
        M4 : 'May',
        M5 : 'June',
        M6 : 'July',
        M7 : 'August',
        M8 : 'September',
        M9 : 'October',
        M10 : 'November',
        M11 : 'December',
        M12 : 'Invalid Month'
    },
    MONTHS : {
        M0 : 'Jan',
        M1 : 'Feb',
        M2 : 'Mar',
        M3 : 'Apr',
        M4 : 'May',
        M5 : 'Jun',
        M6 : 'Jul',
        M7 : 'Aug',
        M8 : 'Sep',
        M9 : 'Oct',
        M10 : 'Nov',
        M11 : 'Dec',
        M12 : 'Invalid Month'
    }
} as any;

type MonthType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const getMonth:(dateText: string, isFullMonth: boolean) => string = (dateText: string, isFullMonth = true) => {
    try {
      const date = new Date(dateText);
      const key: string = `M${date.getMonth() as MonthType}`;
      if (isFullMonth) {
        return I18N_RESOURCE.FULL_MONTHS[key];
      }
      return I18N_RESOURCE.MONTHS[key];
    } catch (exception) {
      return I18N_RESOURCE.FULL_MONTHS['M12'] as any;
    }
  };
  
export const toDate = (dateText: string) => {
    const date = new Date(dateText);
   return `${getMonth(dateText, false)} ${date.getDate()}, ${date.getFullYear()}`;
  };


export const toDateWithTime = (date: any) => {
  const d = new Date(date);
  let currentHours: string = d.getHours().toString();
  currentHours = ("0" + currentHours).slice(-2);

  let currentMins: string = d.getMinutes().toString();
  currentMins = ("0" + currentMins).slice(-2);
  
  return `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()}, ${d.getFullYear()} ${currentHours}:${currentMins}`;
  
}