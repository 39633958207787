import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { useStatusBadgeSyles } from "./SubscriptionList.style";
import { resolveGatewayType } from "../../../utils/TextFormatters";
import {
  IProductDetail,
  IApplication,
  ProductService,
} from "../../../service/ProductService";
import Unsubscriber from "../unsubscriber/Unsubscriber";
import ApplicationKeys from "../../applications/application-keys/ApplicationKeys";
import { formatApplicationType, resolveText } from "legacy-components/Helper";
import { Box } from "legacy-components/responsive-grid/ResponsiveGrid";
import Alert from "legacy-components/alert/Alert";
import Table, { TableColumn } from "legacy-components/table/Table";
import Badge from "legacy-components/badge/Badge";
import { CellClickedEvent } from "ag-grid-community";
import { useHistory } from "react-router";
import { toDate } from "modus/components/list/table/helpers";
import { DEFAULT_IDENTITY_ENVIRONMENT } from "header/environment-selector/ENV_CONSTANTS";

export interface SubscriptionListProps {
  product: IProductDetail;
}

type ISubscriptionStatus =
  | "SUBSCRIBED"
  | "PENDING_REVIEW"
  | "REVOKED"
  | "DENIED";

export const SubscriptionList: React.FC<SubscriptionListProps> = ({
  product,
}) => {
  const [data, setData] = useState<Array<IApplication | null>>();

  useEffect(() => {
    if (product?.productName) {
      void ProductService.getApplications(product.productName, "SUBSCRIBED")
        .toPromise()
        .then((output) => {
          setData(output.data.filter(data => {
                      return data.environment === DEFAULT_IDENTITY_ENVIRONMENT
                    })); 
        });
    }
  }, [product, product?.productName]);

  // To display Product Subscription status as Badge format
  const StatusBadge: React.FC<{ value: ISubscriptionStatus }> = ({ value }) => {
    const classes = useStatusBadgeSyles();
    return (
      <Badge
        label={resolveText(value)}
        className={clsx(classes.status, classes[value])}
      />
    );
  };

  // To View Application Keys and Delete Subscription
  const Actions = (props: { data: any }) => {
    return (
      <Box key={props.data.applicationName} className={"actions"}>
        <ApplicationKeys
          applicationName={props.data.applicationName}
          gatewayName={props.data.environment}
        />
        <Box margin="l1" />
        <Unsubscriber
          productName={product.productName}
          subscriptionId={props.data.subscriptionId}
          applicationName={props.data.applicationName}
        />
      </Box>
    );
  };

  let history = useHistory();
  const onCellClicked = useCallback((event : CellClickedEvent) => {
     history.push(`/home/applications/${event.data.applicationName}/gateway/${event.data.environment}/summary`);
}, []);

  return (
    <>
      <Alert id="UNSUBSCRIBER" />
      <Table
        rowData={data}
        noRowsText="No subscription found. You are yet to subscribe one of your applications to this API Product."
        height="400px"
        frameworkComponents={{ StatusBadge, Actions }}
        data-testid="subscriptions-table"
      >
        <TableColumn
          field="applicationName"
          flex={2}
          cellRenderer="Title"
          headerName="Name"
          data-testid="applicationnName"
          sortable
          onCellClicked = {(event) => onCellClicked(event)}
          unSortIcon={true}
          filter="agTextColumnFilter"
          filterParams={{
            suppressAndOrCondition: true,
            defaultOption: "contains",
            filterOptions: ["contains"],
          }}
        />
        <TableColumn
          field="status"
          flex={0.8}
          cellRenderer="StatusBadge"
          sortable
          unSortIcon={true}
        />
        <TableColumn
          field="type"
          flex={1.2}
          valueFormatter={(params) => formatApplicationType(resolveText(params.value))}
        />
        <TableColumn
          field="environment"
          flex={1}
          valueFormatter={(params) => resolveGatewayType(params.value)}
          sortable
          unSortIcon={true}
        />
        <TableColumn
          field="createdAt"
          flex={1}
          headerName="Date Created"
          valueFormatter={(params) => toDate(params.value)}
          sortable
          unSortIcon={true}
        />
        <TableColumn field="actions" flex={1} cellRenderer="Actions" />
      </Table>
    </>
  );
};

export default React.memo(SubscriptionList);
