import React, { useState, useEffect, useRef } from 'react';
import { createStyles, withStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useRole } from 'libs/security/authorization/Permission';
import { useAsyncAction } from 'legacy-components/form/action/AsyncAction';
import { Box, CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import ModusIcon from 'legacy-components/icons/ModusIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Loader from 'modus/components/misc/Loader';
import TeamServices from 'pages/home/team/TeamService';

export const useTeamStyles = makeStyles(theme => {
  return createStyles({
    container: {
      borderLeft: '1px solid rgb(183, 185, 195)',
      paddingLeft: '20px',
      height: '44px',
      marginLeft: '20px'
    },
    initial: {
      border: '1px solid #e0e1e9',
      height: '42px',
      borderBottom: '1px solid #6a6e79',
      display: 'flex',
      alignItems: 'center',
      padding: '0 30px'
    },
    initialIcon: {
      fill : '#0063a3',
      color : '#0063a3',
      paddingRight: "20px",
      zIndex: 1
    },
    dropIcon: {
      marginRight:'10px'
    },
    teamIcon: {
      position: 'absolute',
      top: '10px',
      left: '15px',
      fill : '#0063a3',
      color : '#0063a3',
      zIndex: 1
    }
  })
});
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 'auto',
      width: "auto",
      maxWidth: '300px',
      textOverflow: 'ellipsis',
      border: '1px solid #e0e1e9 !important',
      borderBottom: '1px solid #6a6e79 !important',
      height: '42px',
      marginLeft: '0px',
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 2,
      position: 'relative',
      paddingLeft: '51px',
      color: '#000',
      fontSize: 12,
      fontWeight: 400,
      padding: '10px 26px 10px 12px',

      //transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
         'Open Sans', 'sans-serif'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#000',
        color: '#000',
        backgroundColor: "#fff"
      }
    },
  }),
)(InputBase);

export const MY_TEAM_KEY = "MY_TEAM_KEY"
export const MY_SELECTED_TEAM_ = "MY_SELECTED_TEAM_"

export default function TeamSelector(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [rolepermission, state] = useRole();
  const teamClasses = useTeamStyles();
  const [team, setTeam] = React.useState(sessionStorage.getItem(MY_TEAM_KEY) ?? rolepermission?.teamId);
  const [, , changeTeam] = useRole();
  
    const handleChange = (event: React.ChangeEvent<any>) => {
    toggleLoading(!isLoading);
    changeTeam(event.target.value);
    sessionStorage.setItem(MY_TEAM_KEY, event.target.value as string);
    setTeam(event.target.value as string);
    dispatch(history.push("/home"));
    return true;
  };
  const [, setTeams] = useState<any>();
  const [isLoading, toggleLoading] = useState<boolean>(false);

  const getUserTeams = (teams: any) => {
    setTeams(teams);
    return teams;
  }
  const [response, { subscribe }] = useAsyncAction(() => TeamServices.getUsersTeam(),
    {
      mapFn: getUserTeams
    });

    useEffect(() => {
      if(!subscribe) {
        return;
      }
      const unsubscribe = subscribe()
  
      return () => unsubscribe();
    }, [subscribe]);
  
  
  useEffect(() => {
    if(!setTeam || !changeTeam || !response.value ) {
      return;
    }
    
    setTeam(sessionStorage.getItem(MY_TEAM_KEY) ??  state.value?.teamId)
    changeTeam(sessionStorage.getItem(MY_TEAM_KEY) ??  state.value?.teamId);
    
  }, [setTeam, changeTeam,  state?.value?.teamId, response.value]);



  useEffect(() => {
    toggleLoading(!isLoading);
  }, [state?.value?.teamId]);


  const selectRef = useRef<any>(null);

  const handleIconClick = () => {
    selectRef?.current?.focus(); // This will open the dropdown
  };

  return (
    <>
    {response?.value && isLoading &&
        <div className="team-selection-loader">
          <Loader showBackDrop={true} />
        </div>

      }
    <Box className={teamClasses.container}>
    <Box width="auto">
      <div className="dropdown">

        {(response?.value && response?.value.count >= 1  && team ? <FormControl>
          <ModusIcon
             name={'people_group'}
            className={teamClasses.teamIcon}
              />
          <Select
            value={team ?? state?.value?.teamId }
            fullWidth={false}
            variant="outlined"
            className="header-selector"
            onChange={handleChange}
             input={<BootstrapInput />}
            IconComponent={(props) => <ExpandMoreIcon 
              {...props} className={clsx([teamClasses.dropIcon, props.className])} />}
          >

            {Object.keys(response?.value.values).map(key => (

              [
              <MenuItem key={key} disabled style={{color: theme.palette.primary.main, opacity: 1}}>{key}</MenuItem>,
                response?.value.values[key].map((item: any, index: number) => (
                  <MenuItem key={index} className={clsx(["selector-teams"])}
                    value={item.team.id}>{item.team.displayName}</MenuItem>

                ))
              ]

            ))}
          </Select>
         
        </FormControl>
          : 
          <Box className={teamClasses.initial}>
          <ModusIcon
             name={'people_group'}
            className={teamClasses.initialIcon}
              />
          {response.loaded && (<Typography variant="body2" className="sector" >
            {props.group}
          </Typography> )}
          {response.loading && <CircularProgress color='primary' size="20px" style={{marginLeft:"15px"}}/>}      
          </Box>
        )}
      </div>
    </Box>
    </Box>
    </>
  );
}
