import React from "react";
import {
  Box,
  createStyles,

  makeStyles,
  Typography,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "30px",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    services: {
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
    },
    appLink: {
      textDecoration: "none",
      color: "#00437B !important",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);




/**
 * @function WelcomeBanner
 * @description component contains welcome message and quick links
 */
export const WelcomeBanner = () => {
  return (
    <section>
        <Typography variant="h4" align="center" gutterBottom color="primary" style={{color: "#00437B", fontWeight: 600}}>
          Welcome to Trimble Cloud Console
        </Typography>
        <Typography variant="h6" align="center" gutterBottom color="secondary" style={{color: "#019AEB"}}>
          A one-stop portal for accessing all Trimble Cloud Services
        </Typography>
      </section>
  );
}

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#585C65',
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: "8px",
    borderRadius: "4px",
    position: "relative",
    display: "none",
    
  },
  tooltipContainer: {
    position: "absolute",
    zIndex: 100000,
    left: 8
  },
  alert: {
    cursor: "pointer",
    "&:hover $tooltip": {
      display: "block"
    }
  },
  arrow: {
    content: "",
    position: "absolute",
    top: "50%",
    left: "-10px",
    transform: "translateY(-50%)",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: `transparent #585C65 transparent transparent`
  },
  
}));


/**
 * @class Starter
 * @description component contains console feature listing
 */
const Starter = (props: any) => {

  const classes = useTooltipStyles();

  const features = [ 
    {
      "name": "Add sign in with Trimble Identity to your application",
      "top": -17,
      "content": <>
          Click Applications Menu <br/>
          This will take you to Oauth Application listing page. <br/>
          Click Create New Application Button <br/>
          This will present you a form to fill your application requirement.
      </>

    },{
    "name": "Consume an API or Event",
    "top": 102,
    "content": <>
      Click Products Menu <br/>
      This page will list all the Cloud Products including APIs and Events. 
    </>
  }, {
    "name": "Publish an API or Event",
    "top": 300,
    "content": <>
      Click Products Menu.<br/>
      You can include one or more APIs in your product. <br/>
    </>
  }]
  return (
      <Box m={3}>
        <WelcomeBanner/>
        <Box pt={2} />
        {features.map((feature, index) => (
          <React.Fragment key={index}>
            <Alert variant="outlined" className={classes.alert} iconMapping={{ info: <InfoIcon/>}} severity="info" key={index}>
              {feature.name}
              <Box className={classes.tooltipContainer} style={{top: feature.top}}>
              <Box  className={classes.tooltip}>
                <Box>
                  {feature.content}
                </Box>
                <div className={classes.arrow} />
            </Box>
            </Box>
            </Alert>
            <Box pt={2} />
            
          </React.Fragment>
        ))}
        
      </Box>
  );
};
export default Starter;
