import { forkJoin, from } from "rxjs";
import { map } from "rxjs/operators";
import {
  APIMethod,
  APIResource,
} from "../../utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "../../utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "../../utils/http/client/HttpClient";

export interface IAPIProxyDeployment {
  deployStatus: string;
  environmentName?: string;
  backendUrl: string;
  deployedUrl: string;
}

export interface IAPIProxy {
  id: string;
  apiName?: string;
  description?: string;
  apiContext?: string;
  version?: string;
  publishStatus?: string;
  apiDeployments?: IAPIProxyDeployment[];
  resourcePath?: string;
  permissions?: string;
  isShared?: boolean;
  accessRole?: AccessRoleType;
  isVerified?: number;
}

export interface IAPIProxyDeployRequest {
  backendUrl?: string;
  timeout?: number;
  basepath?: string;
  threatProtection?: string;
  tls?: string;
  isAdvanced?: boolean;
  migrateApi?: boolean;
  jwtSupport?: Array<string>;
}

export interface IAPINoAuth {
  requestPath: string;
  apiId: string;
}
export interface IAPIProxyNoAuthRequest {
  requestVerb: string;
  requestPath: string;
  id: string;
  apiName: string;
  environment: string;
}
export interface IAPIProxyNoAuthResponse {
  apiId: string;
  environment: string;
  pathVerbDetails: PathVerbDetails[];
}
export interface PathVerbDetails {
  path: string;
  methods: PathMethods[];
}
export interface PathMethods {
  method: string;
  noAuthId: string;
}
export interface APICreateRequest {
  displayName: string | undefined;
  version: string | undefined;
  context: string | undefined;
  namespace: string | undefined;
  resourceServerName: string | undefined;
}
export type AccessRoleType = "viewer" | "editor";

export interface AudienceMetadata {
    apiName: string;
    applicationName: string;
    audience: string;
    status: boolean;
    environmentName: string;
    updated: string;
}

@APIResource(CloudConsoleClient, "publisher")
class ProxyServiceFactory {
  public environments: any = {
    dev: {
      deploy: (apiName: string, request: any) =>
        this.deploy("dev", apiName, request),
      undeploy: (apiName: string) => this.undeploy("dev", apiName),
    },
    qa: {
      deploy: (apiName: string, request: any) =>
        this.deploy("qa", apiName, request),
      undeploy: (apiName: string) => this.undeploy("qa", apiName),
    },
    stage: {
      deploy: (apiName: string, request: any) =>
        this.deploy("stage", apiName, request),
      undeploy: (apiName: string) => this.undeploy("stage", apiName),
    },
    prod: {
      deploy: (apiName: string, request: any) =>
        this.deploy("prod", apiName, request),
      undeploy: (apiName: string) => this.undeploy("prod", apiName),
    },
    "eu-west": {
      deploy: (apiName: string, request: any) =>
        this.deploy("eu-west", apiName, request),
      undeploy: (apiName: string) => this.undeploy("eu-west", apiName),
    }
  };

  @APIMethod("/api")
  public forTeam(includeDeployment: boolean) {
    return from(
      CloudConsoleClient.get<IAPIProxy[]>(apiMethodContext(arguments), {
        params: {
          list: "team",
          includeDeployment,
        },
      }).then((value) => value.data)
    );
  }

  @APIMethod("/api/:apiName")
  public forApi(apiName: string) {
    return from(
      CloudConsoleClient.get<IAPIProxy>(apiMethodContext(arguments), {
        params: {
          apiName,
          includeEmailAddress: true,
        },
      }).then((value) => value.data)
    );
  }

  @APIMethod("/product/:productId/apis")
  public forProduct(productId: string) {
    return from(
      CloudConsoleClient.get<IAPIProxy[]>(apiMethodContext(arguments), {
        params: {
          productId,
        },
      }).then((value) => value.data)
    );
  }

  public withAssociation(productId: string) {
    return forkJoin(this.forTeam(true), this.forProduct(productId)).pipe(
      map(([list, mappedList]) => {
        const mapper = new Map(mappedList.map((value) => [value.id, value]));

        return list.map((value) => {
          return {
            id: value.id,
            value,
            assigned: mapper.has(value.id),
          };
        });
      })
    );
  }

  @APIMethod("/api/:apiName/environment/:environmentName/deploy")
  public deploy(
    environmentName: any,
    apiName: string,
    request: IAPIProxyDeployRequest
  ) {
    const context = apiMethodContext(arguments);

    return new Promise<any>((resolve, reject) => {
      CloudConsoleClient.post(context, request, {
        params: {
          apiName,
          environmentName,
        },
      }).then(
        (response) => resolve(response.data),
        (error) =>
          reject(
            error.response?.data?.message ??
              "Unknown error occured while deploying the proxy"
          )
      );
    });
  }

  @APIMethod("/api/:apiName/environment/:environmentName/deploy")
  public updateDeployment(
    environmentName: any,
    apiName: string,
    request: IAPIProxyDeployRequest
  ) {
    const context = apiMethodContext(arguments);

    return new Promise<any>((resolve, reject) => {
      CloudConsoleClient.put(context, request, {
        params: {
          apiName,
          environmentName,
        },
      }).then(
        (response) => resolve(response.data),
        (error) =>
          reject(
            error.response?.data?.message ??
              "Unknown error occured while deploying the proxy"
          )
      );
    });
  }

  @APIMethod("/api/:apiName/environment/:environmentName/undeploy")
  private undeploy(environmentName: any, apiName: string) {
    const context = apiMethodContext(arguments);

    return new Promise<void>((resolve, reject) => {
      CloudConsoleClient.delete(context, {
        params: {
          apiName,
          environmentName,
        },
      }).then(
        (response) => resolve(response.data),
        (error) =>
          reject(
            error.response?.data?.message ??
              "Unknown error occured while undeploying the proxy"
          )
      );
    });
  }

  @APIMethod("/api/:apiName/create")
  public createProxy(apiName: string | undefined, request: APICreateRequest) {
    const context = apiMethodContext(arguments);

    return new Promise<void>((resolve, reject) => {
      CloudConsoleClient.post(context, request, {
        params: {
          apiName,
        },
      }).then(
        (response) => resolve(response.data),
        (error) => {
          let err = error.response?.data;
          let msg = err[0] ? err[0].message : err?.message;
          reject(msg ?? "Unknown error occured while deploying the proxy");
        }
      );
    });
  }

  @APIMethod("/api/audMetadata/:apiName/gateway/:gatewayName")
  public getAudienceMetadata(apiName: string, gatewayName: string) {
    return from(CloudConsoleClient.get<AudienceMetadata[]>(apiMethodContext(arguments), {
      params: {
        apiName, gatewayName,
      }}).then(value => value.data));
  }

  @APIMethod("/api/:apiName/gateway/:gatewayName/enforceAudience?isEnforceAudience=:isEnforceAudience")
  public enforceAudience(apiName: string, gatewayName: string, isEnforceAudience: boolean) {
    return from(CloudConsoleClient.get<AudienceMetadata[]>(apiMethodContext(arguments), {
      params: {
        apiName, gatewayName, isEnforceAudience,
      }}).then(value => value.data));
  }
}

const ProxyService = new ProxyServiceFactory();

export default ProxyService;
