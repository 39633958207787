import { Box, Button, DialogTitle, ThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { FormattedMessage } from "react-intl";
import Table, { TableColumn } from "legacy-components/table/Table";
import Badge from "legacy-components/badge/Badge";
import { TeamJoiner } from "./TeamJoiner";
import { Teamcreator } from "./TeamCreator";
import { theme } from "themes/mui";

export interface TeamListViewerProps {
  open: boolean;
  toggle: () => void;
  data: any;
  setSelectedTeamName: any;
  handleChangeNew: any;
  refreshUserTeams: any;
  userUuid: string;
}

export const TeamListViewer: React.FC<TeamListViewerProps> = ({
  open,
  toggle,
  data,
  setSelectedTeamName,
  handleChangeNew,
  refreshUserTeams,
  userUuid
}) => {
  const StatusBadge = ({ data }: any) => {
    return (
      <Box style={{ justifyContent: "space-between" }}>
        <Badge color="primary" label={data?.role?.iamRole?.toUpperCase()} />
      </Box>
    );
  };

  const redirectTeam = (data: any) => {
    handleChangeNew(data?.data?.team);
    setSelectedTeamName(data?.data?.team?.teamName);
    toggle();
  };
  return (
      <Dialog
        open={open}
        title="Select a Team"
        onClose={toggle}
        fullWidth={true}
        style={{ padding: "24px 21px 24px 21px" }}
      >
        <ThemeProvider theme={theme}>
          <div>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormattedMessage defaultMessage="Select a team" />
              </DialogTitle>
              <TeamJoiner refreshUserTeams={refreshUserTeams} userUuid={userUuid}/>
              <Teamcreator refreshUserTeams={refreshUserTeams}/>
            </Box>

            <Box style={{ padding: "30px 30px 30px 30px" }}>
              <Table
                noRowsText={`You are not associated with any team`}
                rowData={data}
                height="506px"
                pagination = {false}
              >
                <TableColumn
                  field="team.teamName"
                  flex={2}
                  cellRenderer="Title"
                  headerName="Name"
                  sortable
                  unSortIcon={true}
                  filter="agTextColumnFilter"
                  filterParams={{
                    suppressAndOrCondition: true,
                    defaultOption: "contains",
                    filterOptions: ["contains"],
                  }}
                  onCellClicked={redirectTeam}
                />
                <TableColumn
                  field="role.iamRole"
                  flex={2}
                  sortable
                  unSortIcon={true}
                  headerName="Role"
                  cellRendererFramework={StatusBadge}
                />
              </Table>
              <Box mx={1} style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "end"}}>
              <Button onClick={toggle} color="secondary" variant="outlined">Cancel</Button>
              </Box>

            </Box>
          </div>
        </ThemeProvider>
      </Dialog>
  );
};
