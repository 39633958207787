import React, { useCallback, useContext, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";


import { Tab, Tabs, Chip } from "@material-ui/core";
import { IProductDetail, ProductService } from "../../../service/ProductService";
import { Summary } from "./summary/Summary";
import { APIReference } from "./apireference/APIReference";
import useProductStyles from "./product.style";
import Subscriber from "../../subscriptions/subscriber/Subscriber";
import SubscriptionList from "../../subscriptions/subscription-list/SubscriptionList";
import { Column } from "legacy-components/responsive-grid/ResponsiveGrid";
import Typography from "legacy-components/typography/Typography";
import TabPanel from "legacy-components/tab-panel/TabPanel";
import AlertService from "legacy-components/alert/AlertService";
import { Alert } from "@material-ui/lab";
import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";
import TeamServices from "pages/home/team/TeamService";
import { TeamDetail } from "pages/home/team/models/team.model";
import { StatusBadge } from "legacy-components/badges/StatusBadge";
import { DEFAULT_IDENTITY_ENVIRONMENT } from "header/environment-selector/ENV_CONSTANTS";

interface ProductDataProps {
  name: string;
}
interface IProductContext {
  product: IProductDetail | null | undefined;
  team: TeamDetail | null | undefined;
  refresh: () => void;
  teamRefresh: () => void;
}
export const ProductContext = React.createContext<IProductContext | null>(null);

export const Data: React.FC<PropsWithChildren<ProductDataProps>> = ({
  name,
  children,
}) => {
  const [productDetails, setProductDetails] = useState<
    IProductDetail | null | undefined
  >(undefined);
  
  const [teamName, setTeamName] = useState<string|undefined>(undefined);
  const [teamDetail, setTeamDetail] = useState<
    TeamDetail | null | undefined
  >(undefined);

  const classes = useProductStyles();
  useEffect(() => {
    void ProductService.getProductDetail(name).toPromise()
      .then(
        (output) => {
          setProductDetails(output.data);
          setTeamName(output.data.teamName);
        },
        (error) => {
          setProductDetails(null);
          AlertService.error({
            message: "No Such Product Found",
            isAutoHide: false,
            id: "PRODUCT",
          });
        }
      );
  }, [name]);


  useEffect(() => {
    void TeamServices.forTeamDetails(teamName).toPromise()
      .then(
        (output) => {
          setTeamDetail(output);
        },
        (error) => {
          setTeamDetail(null);
          AlertService.error({
            message: "No Team Found",
            isAutoHide: false,
            id: "Team",
          });
        }
      );
  }, [teamName]);
  const refresh = useCallback(() => {
    productDetails && setProductDetails({ ...productDetails });
  }, [productDetails]);

  const teamRefresh = useCallback(() => {
    teamDetail && setTeamDetail({ ...teamDetail });
  }, [teamDetail]);
  return (
    <ProductContext.Provider value={{ product: productDetails, team: teamDetail, refresh, teamRefresh }}>
      <section className={classes.root}>
        {productDetails === null && <Alert severity="error">The following product is restricted to the following user for the selected team </Alert>}
        {productDetails !== null && productDetails !== undefined && children}
      </section>
    </ProductContext.Provider>
  );
};

// Section displayed at top of the Product Page
interface ProductBasics {
  switchTab: (tabId: number) => void;
}
export const Basics: React.FC<ProductBasics> = ({ switchTab }) => {
  return (
    <ProductContext.Consumer>
      {(context) => (
        <GenericPageTitle
          title={context?.product?.displayName}
          subTitle={context?.product?.teamDisplayName}
          badges={context?.product?.publishedInfo}
          controls={
            context?.product && (
              <Subscriber onSubscriptionSuccess={() => switchTab(2)} />
            )
          }
          async
        />
      )}
    </ProductContext.Consumer>
  );
};

interface TabMenuProps {
  initialTab: number;
  specUrl: string;
}

interface IMenu {
  title: string;
  panel: React.FC<any>;
  props?: any;
}
export const TabMenu: React.FC<TabMenuProps> = ({ initialTab, specUrl }) => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const productContext = useContext(ProductContext);

  const [data, setData] = useState<number>();

  useEffect(() => {
    if (productContext?.product?.productName) {
      void ProductService.getApplications(productContext?.product.productName, "SUBSCRIBED")
        .toPromise()
        .then((output) => {
          setData(output.data.filter(data => {
                                return data.environment === DEFAULT_IDENTITY_ENVIRONMENT
                              }).length);
        });
    }
  }, [productContext?.product, productContext?.product?.productName]);
  
  const menuList: Array<IMenu> = [
    {
      title: "Summary",
      panel: Summary,
    },
    {
      title: "API Reference",
      panel: APIReference,
      props: {
        redocServerUrl: "",
        specDetails: productContext?.product?.specDetails,
        productName: productContext?.product?.productName,
        specUrl,
      },
    },
    {
      title: "Subscriptions",
      panel: SubscriptionList,
      props: {
        product: productContext?.product,
      },
    },
  ];

  useEffect(() => {
    setCurrentTab(initialTab);
  }, [initialTab]);

  const onTabClick = (event: unknown, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Column margin="b2" padding="2" >
        <Tabs
          value={currentTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={onTabClick}
        >
          {menuList.map((menu, index) => (
            <Tab
              key={index}
              label={
                <Typography type="h6" transform="upper">
                  {menu.title}{" "}
                  {index == 2 && 
                  <Chip size="small" label={data} color="primary" />}
                </Typography>
              }
              data-testid = {`${menu.title}-menu`}
            />
          ))}
        </Tabs>
      </Column>
      {menuList.map((menu, index) => (
        <TabPanel key={index} index={index} value={currentTab}>
          {React.createElement(menu.panel, menu.props ?? null)}
        </TabPanel>
      ))}
    </>
  );
};

