import { Box, Button, Dialog, DialogTitle, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import TeamCreationForm from "pages/home/sector/teams/TeamCreationForm";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { Field, FormRenderProps } from "react-final-form";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormContainer from "legacy-components/form/container/FormContainer";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { FORM_ERROR, FormApi } from "final-form";
import { sanitize } from 'dompurify';

export interface TeamCreatorProps {
    refreshUserTeams: any
}
export const Teamcreator: React.FC<TeamCreatorProps> = ({refreshUserTeams}) => {
    const { open, toggle } = useDialogProps();
    const onCreateTeamClick = () => {
        toggle();
    }
    const intl = useIntl();
    const onCancel = () => {
      toggle();
    }
    const onSuccess = () => {
      toggle();
    }

    const onSubmit = () => (data: any, form: FormApi) => {
      
      return new Promise((resolve) => {
          const formState = form.getState();

          if (formState.pristine) {
              resolve(null);
          } else {
              UnifiedAPIService.post(
                  UnifiedAPI.parseRequestURL(UnifiedAPI.add_new_team_simplified, {
                  }),
                  {
                      teamName: data.teamName,
                      description : sanitize(data.description),
                  }
              ).then(() => {
                refreshUserTeams();
                resolve(null)
              }, error => {
                  resolve({
                      [FORM_ERROR]: error.response?.data?.message ?? " Cannot Create Team"
                  });
              });
          }
      });
  }
    
    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
      const validator = useContext(ValidatorBuilderContext);
      return (<>
          <FormInputContainer
             title={<Typography variant="subtitle2">
              <FormattedMessage defaultMessage="Team Name" />
              <span style={{ color: "#DA212C" }}> *</span>
          </Typography> }
          >
              <Field
                  name="teamName"
                  type="text"
                  component={FormTextInput}
                  placeholder={intl.formatMessage({
                      defaultMessage: "Team Name"
                  })}
                  validate={validator.from({
                      required: true
                  })}
              />
          </FormInputContainer>
          <FormInputContainer  title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Description" />} />}>
              
          <Field
                          name="description"
                          type="text"
                          multiline
                          rows={5}
                          component={FormTextInput}
                          placeholder={intl.formatMessage({
                              defaultMessage: "Tell us a little bit about your team"
                          })}
                      />
              </FormInputContainer>
              
          <ActionInliner>
              <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

              <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Create Team" /></Button>
          </ActionInliner>
      </>);
  }
    return(
        <>
        <Button startIcon={<AddIcon />} onClick={onCreateTeamClick} variant="contained" color="primary" 
        >
        <FormattedMessage defaultMessage="Create a new Team" />
    </Button>
        <Box>

            <Dialog
        open={open}
        title="Add New Team"
        onClose={toggle}
        fullWidth={true}
        style={{ padding: "24px 21px" }}
      >
        <div>
          <DialogTitle>
            <FormattedMessage defaultMessage="Create a team" />
          </DialogTitle>
        </div>
        <Box style={{margin: "20px 20px 20px 20px"}}>
     <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
        
        </Box>
      </Dialog>
        </Box>
        </>
    )

}