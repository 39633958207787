import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Select, MenuItem, TextField, Typography, IconButton, Tooltip, Snackbar } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TrimbleColors } from 'legacy-components/TrimbleColors';
import { resolveEventFilterToText } from './filter';
import { resolveTextToFilter } from './filter';
import EventService from '../service/EventService';
import { useValidateFilterCondition } from './EventFilterValidation';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from "@material-ui/lab";

interface EventDrawerProps {
    open: boolean;
    onClose: () => void;
    event_details: any;
    subscriptionId: string;
    onChange: ()=> void;
}

const useStyles = makeStyles({
    customTooltip: {
      fontSize: '16px',
      lineHeight: 1.7,
    },
    alert: {
        fontSize: '18px'
    },
    alertIcon: {
        fontSize: '25px'
    }
  });

const StyledSelect = withStyles({
    root: {
      fontFamily: 'sans-serif',
      fontSize: '18px',
      fontWeight: 300,
    },
    icon: {
      color: TrimbleColors.Blue,
    },
  })(Select);

const EventDrawer: React.FC<EventDrawerProps> = ({ open, onClose, event_details, subscriptionId, onChange }) => {
    const classes = useStyles();
    const [selectedServiceInDrawer, setSelectedServiceInDrawer] = useState<string>('');
    const [selectedDeploymentInDrawer, setSelectedDeploymentInDrawer] = useState<string>('');
    const [selectedDescriptionInDrawer, setSelectedDescriptionInDrawer] = useState<string>('');
    const [filter, setFilter] = useState<string>('');
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const { validateFilterCondition } = useValidateFilterCondition();
    const [isFilterValid, setIsFilterValid] = useState<boolean>(true);

    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');

    // Replace with your actual service and deployment lists
    const [serviceList, setServiceList] = useState<string[]>(['DataOcean - v1', 'Processing Framework - v1', 'Processing Framework - v2']);
    const [serviceDeploymentList, setServiceDeploymentList] = useState<string[]>(['DataOcean - v1', 'Processing Framework - v1', 'Processing Framework - v2']);

    useEffect(() => {
        if (event_details != null){
            setSelectedServiceInDrawer(event_details.serviceVersion);
            setSelectedDeploymentInDrawer(event_details.serviceDeployment);
            setSelectedDescriptionInDrawer(event_details.description);
            setFilter(resolveEventFilterToText(event_details.filter));
        }
        
    }, [event_details]);

    useEffect(() => {
        setIsFormValid(
            selectedDescriptionInDrawer.trim() !== '' && 
            filter !== undefined &&
            isFilterValid === true
        );
    }, [selectedDescriptionInDrawer, filter, isFilterValid]);

    const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedServiceInDrawer(event.target.value as string);
    };

    const handleDeploymentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDeploymentInDrawer(event.target.value as string);
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'description') {
            setSelectedDescriptionInDrawer(value);
        }
        if (name === 'filter'){
            setFilter(value);
            if(value===''){
                setIsFilterValid(true);
            }else{
            setIsFilterValid(false);
            }
        }
    };


    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            
            try {
                const requestPayload  = {
                    description: selectedDescriptionInDrawer,
                    filter: filter !== '' ? resolveTextToFilter(filter) : null,
                };

                const response = await EventService.updateEventSubscription(requestPayload, subscriptionId, event_details.subscriptionTrn).toPromise();
                if(response.status === 200) {
                    onChange();
                    onClose();
                } else {
                    setAlertMessage(`Error: ${response.status} - ${response.statusText}`);
                    setAlertVisible(true);
                }
                
            } catch (error) {
                console.error("Error while updating subscription:", error);
                setAlertMessage(`Error while updating event configuration`);
                setAlertVisible(true);
            }
        }        
    };

    const handleVerifyClick = () => {
        //api call to verify filter scenarios
        const validationResult = validateFilterCondition(filter);
        setIsFilterValid(validationResult);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} style={{ width: 950 }}>
            <Snackbar
                open={alertVisible}
                autoHideDuration={6000}
                onClose={() => setAlertVisible(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" onClose={() => setAlertVisible(false)} classes={{ message: classes.alert, icon: classes.alertIcon }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box p={2} style={{ width: 933, paddingLeft: 54, maxHeight: '100vh', overflowY: 'auto' }}>
                <Typography style={{ marginBottom: 16, marginLeft:1, fontSize: '24px' }}>
                    Update Configuration
                </Typography>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300, marginBottom: 19, lineHeight: '20px', textAlign: 'left', letterSpacing: '0.15000000596046448px' }}>
                    Create a new event specific to your environment and version
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    <Box mb={2} style={{}}>
                        <Typography style={{ marginBottom: 8, fontSize: '19px', fontWeight: 400, marginTop: 40 }}>
                            Versions
                        </Typography>
                        <StyledSelect
                            disabled
                            fullWidth
                            variant="outlined"
                            value="service_version"
                            style={{width: 800, backgroundColor: '#e0dce4'}}
                            IconComponent={ExpandMoreIcon}
                        >
                        <MenuItem value="service_version">{selectedServiceInDrawer}</MenuItem>
                        </StyledSelect>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '19px', fontWeight: 400 }}>
                            Deployment
                        </Typography>
                        <StyledSelect
                        disabled
                            fullWidth
                            variant="outlined"
                            value="service_deploy"
                            style={{width: 800, backgroundColor: '#e0dce4'}}
                            IconComponent={ExpandMoreIcon}
                        >
                        <MenuItem value="service_deploy">{selectedDeploymentInDrawer}</MenuItem>
                        </StyledSelect>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '19px', fontWeight: 400 }}>
                            Description <span style={{ color: 'black', marginLeft: 3 }}>*</span>
                        </Typography>
                        <TextField
                            name="description"
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            placeholder="Add short description here"
                            value={selectedDescriptionInDrawer}
                            variant="outlined"
                            InputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',         
                                    fontWeight: 300,
                                },
                            }}
                            inputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',
                                    fontWeight: 300,           
                                },
                            }}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 4,
                                width: 800,
                                marginTop: 4
                            }}
                        />
                    </Box>
                    <Typography style={{ marginBottom: 5, marginTop: 50, fontSize: '19px', fontWeight: 600 }}>
                        Filter (Optional)
                        <Tooltip title="Use regex pattern for field comparisons.
                                        Operators: && for AND, || for OR.
                                        Example: FieldName1 = Regex1 && FieldName2 = Regex2" placement="right" arrow    
                                        classes={{ tooltip: classes.customTooltip }}>
                        <IconButton size="medium" style={{ marginLeft: 8 }}>
                            <InfoOutlinedIcon style={{ color: TrimbleColors.Blue, fontSize: '30px' }} />
                        </IconButton>
                        </Tooltip>
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2} style={{width: 800}}>
                        <TextField
                            name="filter"
                            onChange={handleFormChange}
                            fullWidth
                            rows={4}
                            value={filter}
                            placeholder="Add your filter here"
                            variant="outlined"
                            InputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',         
                                    fontWeight: 300,
                                },
                            }}
                            inputProps={{
                                style: {
                                    fontFamily: 'sans-serif',  
                                    fontSize: '18px',
                                    fontWeight: 300,           
                                },
                            }}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 4,
                                width: 800,
                                marginTop: 4,
                                height: '56px'
                            }}
                        />
                        <Button
                            variant="outlined"
                            onClick={handleVerifyClick}
                            style={{
                                marginLeft: 16,
                                marginTop: 3,
                                border: `2px solid ${TrimbleColors.Blue}`,
                                color: TrimbleColors.Blue,
                                backgroundColor: 'transparent',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px',
                                height: '56px'
                            }}
                        >
                            Verify
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2} style={{ marginTop: 100, marginRight: 50 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid}
                            style={{
                                backgroundColor: TrimbleColors.BlueDark,
                                color: '#FFFFFF',
                                textTransform: 'none',
                                padding: '30px 32px',
                                fontSize: '20px',
                                minWidth: '180px',
                                opacity: isFormValid ? 1 : 0.5,
                                cursor: isFormValid ? 'pointer' : 'not-allowed',
                                borderRadius: '6px'
                            }}
                        >
                            Update Configuration
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default EventDrawer;
