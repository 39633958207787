
import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";


export const NotificationPageNavigation: React.FC = () => {
    const { push } =  useHistory();
    return (
        <>
        <NavLink
                className="navigation-tab"
                key={"notifications"}
                activeClassName="active"
                to={'/home/notifications'}
            >
            <ListItem
            button
            onClick={() => push("/home/notifications")}
            >
            <ListItemIcon>
                <NotificationsIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
                className="sub-menu-text"
                primary={<Box display="flex"><Box flexGrow={1}>Notifications</Box>
                        </Box>}
            />
            </ListItem>
            </NavLink>
        </>
        
    )
}
