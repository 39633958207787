import { Box, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { TableContainer } from "modus/components/list/table/TableContainer";
import { withViewController } from "modus/utilities/ViewController";
import React, { useEffect, useMemo, useState } from "react";
import { useMemberListController } from "./MemberListController";
import { AsyncActionState } from "modus/components/form/async-actions/AsyncAction.type";
import { IAPITeam } from "../../services/TeamService";
import { ColDef } from "ag-grid-community";
import Loader from "modus/components/misc/Loader";
import {
  ErrorMessage,
  resolveAPIErrorMessage,
} from "modus/components/error-message/ErrorMessage";
import { MemberDissociator } from "../member-dissociator/MemberDissociator";
import { getRefinedRole, MemberUpdater } from "../member-updater/MemberUpdater";
import { useSelector } from "react-redux";
import { DEFAULT_IDENTITY_ENVIRONMENT } from "header/environment-selector/ENV_CONSTANTS";

interface MemberListViewerProps {
  state: AsyncActionState<IAPITeam[] | null>;
  refresh: () => void;
}

const UserApprovalStatus: React.FC<{ formattedValue?: any; value?: any }> = ({
  value,
}) => {
  const gateway = value?.filter(
    (gateway: any) =>
      gateway.gatewayName?.toUpperCase() ===
      DEFAULT_IDENTITY_ENVIRONMENT?.toUpperCase()
  )?.[0];
  return (
    <Box style={{ textTransform: "capitalize" }}>
      {gateway?.approval?.toLowerCase() ?? "Pending"}
    </Box>
  );
};

const Role: React.FC<{ formattedValue?: any; value?: any }> = ({ value }) => {
  const role =
    getRefinedRole(value) === "TEAM ADMIN" ? "Administrator" : "User";

  return <Box>{role}</Box>;
};
const getName = (value: string) =>
  value?.replaceAll("_", " ")?.replaceAll("-", " ");

const Name: React.FC<{ formattedValue?: any; value?: any }> = ({ value }) => {
  return (
    <span className="grid-title">
      <Typography
        variant="body1"
        color="primary"
        style={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "14px",
        }}
      >
        {getName(value)}
      </Typography>
    </span>
  );
};

const columnDefs: ColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    cellRenderer: "Name",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 3,
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1.5,
    cellRenderer: "Role",
  },
  {
    field: "gateways",
    headerName: "Status",
    flex: 1,
    cellRenderer: "UserApprovalStatus",
  },
];

const adminColumnDefs: ColDef[] = [
  ...columnDefs,
  {
    headerName: "Actions",
    width: 120,
    cellRenderer: "Actions",
  },
];

export const MemberListViewer: React.FC<MemberListViewerProps> = ({
  state,
  refresh,
}) => {
  const user = useSelector((state: any) => state.userManager);

  if (getRefinedRole(user.role) === "TEAM ADMIN") {
    return <AdminViewMemberList state={state} refresh={refresh} />;
  } else {
    return <UserViewMemberList state={state} />;
  }
};
interface UserViewMemberListProps {
  state: AsyncActionState<IAPITeam[] | null>;
}
export const UserViewMemberList: React.FC<UserViewMemberListProps> = ({
  state,
}) => {
  const frameworkComponents: any = { UserApprovalStatus, Role, Name };
  return useMemo(
    () => (
      <Box padding="20px">
        {state?.value && state?.value?.length > 0 && (
          <TableContainer>
            <AgGridReact
              rowHeight={48}
              headerHeight={48}
              domLayout={"autoHeight"}
              rowData={state?.value}
              columnDefs={columnDefs}
              frameworkComponents={frameworkComponents}
              suppressRowClickSelection={true}
              suppressCellSelection={true}
              suppressRowHoverHighlight={true}
              undoRedoCellEditing={true}
            />
          </TableContainer>
        )}
        {state?.value && state?.value?.length === 0 && (
          <ErrorMessage message={"No members found"} />
        )}
        {state?.loading && <Loader showBackDrop />}
        {state?.error && (
          <ErrorMessage message={resolveAPIErrorMessage(state?.error)} />
        )}
      </Box>
    ),
    [state?.value, state?.loading, state?.error]
  );
};

interface AdminViewMemberListProps extends UserViewMemberListProps {
  refresh: () => void;
}
export const AdminViewMemberList: React.FC<AdminViewMemberListProps> = ({
  state,
  refresh,
}) => {
  const Actions: React.FC<any> = (props) => {
    const rowIndex = props.rowIndex;

    const rowData = props?.api?.getModel().rowsToDisplay[rowIndex].data;

    return (
      <Grid item style={{ flexDirection: "row" }} className="grid-title">
        <MemberUpdater user={rowData} onSuccess={refresh} />
        <Box paddingLeft={"10px"} />
        <MemberDissociator
          teamName={rowData.team}
          userId={rowData.id}
          userName={getName(rowData?.name)}
          onDeleteSuccess={refresh}
        />
      </Grid>
    );
  };
  const frameworkComponents: any = { UserApprovalStatus, Role, Name, Actions };
  return useMemo(
    () => (
      <Box paddingTop="20px" paddingBottom="20px">
        {state?.value && state?.value?.length > 0 && (
          <TableContainer>
            <AgGridReact
              rowHeight={48}
              headerHeight={48}
              domLayout={"autoHeight"}
              rowData={state?.value}
              columnDefs={adminColumnDefs}
              frameworkComponents={frameworkComponents}
              suppressRowClickSelection={true}
              suppressCellSelection={true}
              suppressRowHoverHighlight={true}
              undoRedoCellEditing={true}
            />
          </TableContainer>
        )}
        {state?.value && state?.value?.length === 0 && (
          <ErrorMessage message={"No members found"} />
        )}
        {state?.loading && <Loader showBackDrop />}
        {state?.error && (
          <ErrorMessage message={resolveAPIErrorMessage(state?.error)} />
        )}
      </Box>
    ),
    [state?.value, state?.loading, state?.error]
  );
};
export const TeamMemberList = MemberListViewer;
