import clsx from "clsx";
import Badge from "legacy-components/badge/Badge";
import { resolveText } from "legacy-components/Helper";
import Table, { TableColumn } from "legacy-components/table/Table";
import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";
import SubscriptionService, { IAPIApplicationSubscription } from "libs/resources/subscription/SubscriptionService";
import React, { useEffect, useState } from "react";
import { useStatusBadgeSyles } from "../subscription-list/SubscriptionList.style";
import { ISubscriptionStatus } from "../subscription.interface";
import { Box, Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import useProductListStyles from "../../products/product-list/ProductList.style";
import { toDate } from "modus/components/list/table/helpers";

export const TeamSubscriptionList: React.FC = ({}) => {

    const [preProdData, setPreProdData] = useState<Array<IAPIApplicationSubscription | null>>();
    const [prodData, setProdData] = useState<Array<IAPIApplicationSubscription | null>>();
    const [subscriptionLevel, setSubscriptionLevel] = React.useState('team');
    
    const StatusBadge: React.FC<{ value: ISubscriptionStatus }> = ({ value }) => {
        const classes = useStatusBadgeSyles();
        return (
          <Badge
            label={resolveText(value)}
            className={clsx(classes.status, classes[value])}
          />
        );
      };

      interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
      }
      
      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
      }

      const [value, setValue] = React.useState(0);
      const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
      };
    
      const classes = useProductListStyles();
    useEffect(() => {
          let filterSelf = false;
          if(subscriptionLevel === "self") {
            filterSelf = true
          } 
          void SubscriptionService.getTeamSubScriptions(filterSelf, "trimble-pre-prod")
            .toPromise()
            .then((output) => {
              setPreProdData(output);
            });
            void SubscriptionService.getTeamSubScriptions(filterSelf, "trimble-prod")
            .toPromise()
            .then((output) => {
              setProdData(output);
            });
      }, [subscriptionLevel]);

    const controls = (data: Array<IAPIApplicationSubscription | null> | undefined) => {
      return (
      <Table
        rowData={data}
        noRowsText="No subscription found for your Applications"
        height="560px"
        frameworkComponents={{ StatusBadge }}
        data-testid="team-subscriptions-table"
      >
      <TableColumn
        field="applicationName"
        flex={1.2}
        headerName="Application Name"
        data-testid="applicationName"
        sortable
        unSortIcon = {true}
        filter= "agTextColumnFilter"
        filterParams= {{
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            filterOptions: ['contains']
        }}
      />
      <TableColumn
        field="productName"
        flex={1}
        headerName="Product Name"
        data-testid="productName"
        sortable
        unSortIcon = {true}
        filter= "agTextColumnFilter"
        filterParams= {{
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            filterOptions: ['contains']
        }}
      />
      <TableColumn
        field="productTeamName"
        flex={1}
        headerName="Product Team"
        data-testid="productTeamName"
        sortable
        unSortIcon = {true}
        filter= "agTextColumnFilter"
        filterParams= {{
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            filterOptions: ['contains']
        }}        
      />
      <TableColumn
        field="status"
        flex={0.8}
        headerName="Status"
        cellRenderer="StatusBadge"
        sortable
        unSortIcon = {true}
      />
      <TableColumn
        field="subscriberEmail"
        flex={1}
        headerName="Subscribed By"
        sortable
        unSortIcon = {true}
        resizable= {true}
      />
       <TableColumn
        field="subscriptionRequestedTime"
        flex={1}
        headerName="Subscribed Date"
        valueFormatter={(params) => toDate(params.value)}
        sortable
        unSortIcon = {true}
      />
    </Table>
      )}

    

  const handleSubscriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSubscriptionLevel(event.target.value as string);
  };

    const Controls = () => (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Subscriptions</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subscriptionLevel}
          onChange={handleSubscriptionChange}
        >
          <MenuItem value={"team"}>Team</MenuItem>
          <MenuItem value={"self"}>My</MenuItem>
        </Select>
      </FormControl>
  );

    return (
        <>
        <GenericPageTitle
          title={"Subscriptions"} controls={<Controls />}/>
         <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Pre Prod"   id= "simple-tab-0" aria-ontrols= "simple-tabpanel-0"/>
          <Tab label="Prod"   id= "simple-tab-1" aria-controls= "simple-tabpanel-1"/>
         
        </Tabs>
        <TabPanel value={value} index={0}>
          {controls(preProdData)}
          </TabPanel>
          <TabPanel value={value} index={1}>
          {controls(prodData)}
          </TabPanel>
        </>
      );
};