import React from "react";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import {
  Box,
  Button,
  Dialog,
  DialogContentText,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { SubscriptionNavigator } from "pages/home/subscriptions/components/subscription-navigator/SubscriptionNavigator";
import { Skeleton } from "@material-ui/lab";
import { withViewController } from "modus/utilities/ViewController";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { ProductServices } from "pages/home/products/components/ProductServices";
import { FormattedMessage } from "react-intl";
import CompactDialog from "legacy-components/dialog/CompactDialog";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import AssociateTeam from "../components/AssociateTeam";
import {
  GlobalProductDetailModel,
  useGlobalProductDetailsController,
} from "../controller/GuestProductDetailsController";
import { Breadcrumbs } from "modus/components/generic-page-title/Breadcrumbs";

export const GuestProductSummaryDetailsViewer: React.FC<
  GlobalProductDetailModel
> = ({ response }) => {
  const { open: openSubscribe, toggle } = useDialogProps();
  const openSubscriptionPage = () => {
    toggle();
  };

  return (
    <>
      <CompactDialog
        open={openSubscribe}
        onClose={toggle}
        title={<FormattedMessage defaultMessage="Subscription" />}
      >
        <Box px={"45px"} py={3}>
          <Typography
            align="center"
            style={{ fontSize: "18px" }}
            children={
              <FormattedMessage defaultMessage="You have not associated with any Teams yet. join any existing team or create a new team to continue the subscription" />
            }
          />
        </Box>
        <Box px={3} py={3}>
          <Box display="flex" flexDirection="row-reverse">
            <AssociateTeam />
            <Box mx={1} />
            <Button onClick={toggle} color="secondary" variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </CompactDialog>
      {response.loaded && response?.value?.data && (
        <>
          <Breadcrumbs
            addHome={false}
            links={[
              { text: "Home", href: "/guest/home/" },
              { text: "Products", href: "/guest/products/" },
            ]}
            pageTitle={response?.value?.data?.displayName}
          />

          <GenericPageTitle
            title={
              <>
                {response?.value?.data?.displayName ??
                  response.value?.data?.name}
              </>
            }
            description={<>{response?.value?.data?.longDescription}</>}
            controls={
              <SubscriptionNavigator
                openSubscriptionPage={openSubscriptionPage}
              />
            }
            noSubTitle
          />
          {response.value?.data?.services && (
            <ProductServices
              productName={response.value?.data?.name}
              services={response.value?.data?.services}
            />
          )}
          {response.value?.data &&
            (!response.value?.data?.services ||
              response.value?.data?.services?.length === 0) && (
              <ErrorMessage message={"No service found"} />
            )}
        </>
      )}
      {!response.loaded && (
        <>
          <GenericPageTitle noControls noSubTitle />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box py={2}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={"56px"}
                  width="100%"
                />
              </Box>
            </Grid>
          </Grid>
          <Paper style={{ marginTop: "30px" }}>
            <Box p={2} style={{ width: "100%" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                height={"200px"}
                width="100%"
              />
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

export const GuestProductSummaryDetails = withViewController(
  GuestProductSummaryDetailsViewer,
  useGlobalProductDetailsController
);
