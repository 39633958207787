interface EventField {
    key: string;
    value: string;
}
export interface EventFilter {
    fields: EventField[];
    criteria: string;

}
export const resolveEventFilterToText = (filter: EventFilter): string  => {
    let result = filter.criteria; 
    if (filter.criteria === null && filter.fields === null)
        return '';
    filter.fields.forEach((field) => {
        result = result.replace(field.key, `${field.key} = ${field.value}`);
    });
    return result;
}


const processCriteria = (inputText: string) => {
    // Split the input text based on the operators && and ||
    const pairs = inputText.split(/\s*&&\s*|\s*\|\|\s*/);
  
    // Extract only the keys from each pair
    const keys = pairs.map(pair => pair.split(' = ')[0].trim());
  
    // Split the input text to capture the original operators
    const operators = inputText.match(/&&|\|\|/g) || [];
  
    // Reconstruct the string with keys and original operators
    let result = keys[0];
    for (let i = 1; i < keys.length; i++) {
      result += ` ${operators[i - 1]} ${keys[i]}`;
    }
  
    return result;
  };

export const resolveTextToFilter = (text: string): EventFilter => {

    const normalizedText = text.replace(/=/g, ' = ');
    const criteria = processCriteria(normalizedText);
    
    const fieldsInString = normalizedText.split(/\s*&&\s*|\s*\|\|\s*/);
    const eventField: Array<EventField> = [];
    fieldsInString.forEach(pair => {
        const [key, value] = pair.split(' = ').map(str => str.trim());
        eventField.push({key, value});
    })


    // Create the EventFilter object
    const eventFilter: EventFilter = {
        criteria: criteria,
        fields: eventField
    };

    return eventFilter;

};
