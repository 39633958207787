import { Box, Button, Typography } from "@material-ui/core";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import React, { useEffect } from "react";
import TeamServices from "../team/TeamService";
import Dialog from "@material-ui/core/Dialog";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useRole } from "libs/security/authorization/Permission";

export interface TeamUserAssociatorProps {
  action: string;
  teamId: string;
  userUuid: string;
  refreshUserTeams: any;
}

export const TeamUserAssociator: React.FC<TeamUserAssociatorProps> = ({
  action,
  teamId,
  refreshUserTeams,
}) => {
  const [, userRoleState, ] = useRole();

  const { state, subscribe } = useAsyncAction(() =>
    TeamServices.joinUserToTeam(userRoleState?.value?.uuid as string, teamId)
  );
  const onJoinTeam = () => {
    subscribe();
    toggle();
  };
  useEffect(() => {
    if (state?.loaded) {
      refreshUserTeams();
    }
  }, [state?.loaded]);

  const { open, toggle } = useDialogProps();

  return (
    <Box style={{ minHeight: "404px" }}>
      <Button color="primary" variant="outlined" onClick={onJoinTeam}>
        {action}
      </Button>
      <Dialog
        open={open}
        title="Join Team"
        onClose={toggle}
        fullWidth={true}
        style={{ padding: "24px 21px" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            minHeight: "404px",
            justifyContent: "center",
          }}
        >
          <CheckCircle
            style={{ color: "#689248", fill: "#689248", fontSize: "6.25rem" }}
          />
          <Typography style={{ marginTop: "30px" }}>
            Your Request is submitted for approval
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};
