import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { from } from "rxjs";

@APIResource(CloudConsoleClient)
class NotificationServiceFactory {

  @APIMethod("/publisher/approvals?request=REVIEW", {
    options: {
      noTrailingSlash: true,
    },
  })
  public getNotifications(type: string) {
    return from(
      CloudConsoleClient.get<any>(apiMethodContext(arguments), {
        params: {
            type
        }
      }).then(
        (response) => response.data.filter((notification: any) => notification.action.statusType === 'PENDING_APPROVAL')
      )
    );
  }
}
const NotificationService = new NotificationServiceFactory();

export default NotificationService;