import React from 'react';
import { ProductNavigation } from './ProductNavigation';
import { ApplicationNavigation } from './ApplicationNavigation';
import { SubscriptionNavigation } from './SubscriptionNavigation';
import { Box } from '@material-ui/core';
import { TeamNavigation } from './TeamNavigation';
import { NotificationPageNavigation } from './NotificationPageNavigation';

export const NavigationPanel = () => {
    return (
        <Box mt={1}>
            <ApplicationNavigation/>
            <ProductNavigation/>
            <SubscriptionNavigation/>
            {process.env.REACT_APP_USER_ONBOARDING_FLOW === "true" && ( <TeamNavigation/>)}
            {process.env.REACT_APP_USER_ONBOARDING_FLOW === "true" && ( <NotificationPageNavigation/>)}
            
        </Box>
    )
}