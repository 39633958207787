import React, { Suspense } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router'
import ProductSummary from './ProductSummary';
import { GenericPageHeaderSkeleton } from 'legacy-components/page-headers/GenericPageHeader';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'themes/mui';
import { ProductPage } from 'pages/home/products/pages/ProductPage';


const ProductConsumerRoutes = () => {
    return (
        <ThemeProvider theme={theme}>
        <Suspense fallback={<GenericPageHeaderSkeleton />}>
            <Switch>
                <Route exact path="/home/consumer/products" render={() => (<Redirect to="/home/consumer/products/list" />)} />

                <Route exact path="/home/consumer/products/list" render={() => (
                    <ProductPage/>
                )} />

                <Route exact path="/home/consumer/products/:productId" render={() => (
                        <ProductSummary />
                )} />

                 <Route exact path="/home/consumer/products/:productId/subscribe" render={() => (
                        <ProductSummary />
                )} />

                <Route path="*"  render={()=><Redirect to ="/home/consumer/products" />}/>
            </Switch>
        </Suspense>
        </ThemeProvider>
    )
}


export default withRouter(ProductConsumerRoutes)

