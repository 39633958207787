import React, { useState, useEffect, createContext, Dispatch } from "react";
import PropTypes from "prop-types";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import { Container, Box } from "@material-ui/core";
import { IdentityApplicationEnvironmentType } from "libs/resources/identity/applications/PublisherApplicationService";
import {
  useIdentityApplicationInfo,
  IdentityApplicationViewState,
  IdentityApplicationViewAction,
} from "libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { AsyncLoader } from "legacy-components/form/action/AsyncAction";
import ApplicationSummaryDetailView from "./ApplicationSummaryDetailView";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import { resolveAPIErrorWithCode } from "modus/components/error-message/ErrorMessage";
import { ErrorMessages } from "pages/home/subscriptions/components/unsubscribed-application-list/ErroCodeMapping";
import { Breadcrumbs } from "modus/components/generic-page-title/Breadcrumbs";

interface RouterPathProps {
  applicationName?: string;
  environmentName?: IdentityApplicationEnvironmentType;
}
interface ApplicationSummaryPageProps {}
export interface IApplicationSummaryPageContext {
  state: IdentityApplicationViewState | null;
  dispatch: Dispatch<IdentityApplicationViewAction>;
}

export const ApplicationSummaryPageContext =
  createContext<IApplicationSummaryPageContext>({
    state: null,
    dispatch: () => {},
  });

function ApplicationSummaryPage({
  match,
}: RouteComponentProps<RouterPathProps> & ApplicationSummaryPageProps) {
  const history = useHistory();
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const { state, dispatch, response } = useIdentityApplicationInfo({
    applicationName: match.params.applicationName,
    environmentName: match.params.environmentName,
  });

  const [viewContext, setViewContext] = useState({
    state,
    dispatch,
  });

  useEffect(() => {
    if (!match.params.environmentName || !identityEnvironment || !history) {
      return;
    }
    if (match.params.environmentName !== identityEnvironment) {
      history.push(`/home/applications`);
    }
  }, [match.params.environmentName, identityEnvironment, history]);

  useEffect(() => {
    setViewContext({
      state,
      dispatch,
    });
  }, [state, dispatch]);

  return (
    <>
      {response?.error && (
        <Alert severity="error">
          {resolveAPIErrorWithCode(response.error, ErrorMessages)}
        </Alert>
      )}
      <ApplicationSummaryPageContext.Provider value={viewContext}>
        <Box padding={2}>
          {!response?.error && (
            <>
              <Breadcrumbs
                links={[{ text: "Applications", href: "/home/applications" }]}
                pageTitle={state?.application?.displayName}
              />
              <GenericPageTitle
                title={state?.application?.displayName}
                description={
                  state?.application?.description === null ||
                  state?.application?.description === "" ||
                  state?.application?.description === undefined
                    ? state?.application?.displayName
                    : state?.application?.description
                }
                noControls
                noSubTitle
                // To be enabled once the feature is available
                // badge={
                //     <Box style={{ marginLeft: "15px", paddingLeft: "15px" }}>
                //       <Badge color="primary" badgeContent={"Approved"} />
                //     </Box>}
              />
            </>
          )}
        </Box>
        <Container maxWidth={false} style={{ margin: "0px", width: "100%" }}>
          <AsyncLoader
            trigger={!!state.application}
            state={response}
            loader={<></>}
          >
            {state.application && <ApplicationSummaryDetailView />}
          </AsyncLoader>
        </Container>
      </ApplicationSummaryPageContext.Provider>
    </>
  );
}

ApplicationSummaryPage.propTypes = {
  match: PropTypes.any.isRequired,
};

export default withRouter(ApplicationSummaryPage);
