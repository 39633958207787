import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { TeamSummaryPage } from './pages/TeamSummaryPage';

/**
 * @component TeamRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
export const  TeamRoutes  = () => {

    
    return (
        <Switch>
            
            <Route path="/home/teams/:teamId" render={() => (
                <TeamSummaryPage/>
            )} />
        
            <Route path="*"  render={()=><Redirect to ="/home" />}/>

        </Switch>
    );

}

export default withRouter(TeamRoutes);