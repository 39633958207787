import axios, { AxiosInstance } from 'axios';

/**
 * @class UnifiedAPI
 * @type API Service Layer
 * @description For API Detail reference and replication
 *  * setting basic headers for all APIs
 */
export class UnifiedAPI {
     // Basic Details
     static metadata = "/publisher/metadata";

     // Account Management
     static list_sector_teams = "/publisher/sector/{sectorName}/teams";
     static list_team_members = "/publisher/team/{teamName}/users";
     static add_new_team = "/publisher/team";
     static add_new_team_simplified = "/publisher/v2/teams";
     static join_user_to_team = "/publisher/v2/users/{userUuid}/teams/{teamId}";

     static delete_team = "/publisher/team/{teamId}";

     // User Management
     static add_new_user = "/publisher/user";
     static delete_user = "/publisher/user/{userId}?teamName={teamName}";
     static update_user = "/publisher/user/{email}";

     // API Proxy Management
     static list_team_apis = "/publisher/api?list=team";
     static list_shared_apis = "/publisher/api?list=shared";
     static get_team_proxy = "/publisher/api/{apiName}?includeEmailAddress={includeEmailAddress}";
     static add_team_proxy = "/publisher/api/{apiName}/create";
     static delete_proxy = "/publisher/api/{apiName}";
     static deploy_proxy = "/publisher/api/{apiName}/environment/{environmentName}/deploy";
     static get_proxy_throttling_config = "/publisher/api/{apiName}/throttling";
     static api_throttling = "/publisher/api/{apiName}/environment/{environmentName}/api-throttling";
     static api_noauth = "/publisher/api/{apiName}/environment/{environment}/noauth"
     static api_noauth_id = "/publisher/api/{apiName}/environment/{environment}/noauth/{noAuthId}"
     static direct_endpoint = "/publisher/api/{apiName}/environment/{environment}/directEndpoint?isDirectEndpoint={directEndpoint}"

     // API Products
     static product_api = "/publisher/product/";
     static edit_product_api = "/publisher/product/{id}";
     static delete_product_api = "/publisher/product/{productName}";
     static publish_product_api = "/publisher/product/{id}/gateway/{environmentName}/publish?approvalType={approvalType}";
     static map_proxy_product = "/publisher/product/{productName}/mapping";

     // API Sharing
     static share_api = "/publisher/api/{apiName}/user/{emailAddress}/access/{role}/share";
     static unshare_api = "/publisher/api/{apiName}/user/{emailAddress}/unshare";

     // Environments
     static get_all_environments = "/environment";
     static add_proxy_environment = "/publisher/api/{apiName}";
     static edit_proxy_environment = "/publisher/api/{apiName}";
     static remove_proxy_environment = "/publisher/api/{apiName}/environment/{environmentName}/undeploy";

     // Namespaces
     static get_team_namespaces = "/publisher/team/{teamName}/namespaces";
     static add_team_namespaces = "/publisher/sector/{sectorName}/namespaces";
     static get_sector_namespaces = "/publisher/sector/{sectorName}/namespaces";

     // Authz Server
     static create_resource_server = "/publisher/api/{apiName}/gateway/{gatewayName}/resource/{resourceServerName}"
     static add_resource_scopes = "/publisher/api/{apiName}/gateway/{gatewayName}/resource/{resourceServerName}/scope"

     // Approvals
     static approval = "publisher/approvals/?type=API";

     // EnforceAudience
     static enforce_audience = "/publisher/api/{apiName}/environment/{environmentName}/enforceAudience?isEnforceAudience={isEnforceAudience}";

     /**
      * @function parseRequestURL
      * @description to replace the valu to be passed to above string variables
      * @example /api/{apiName}/create - /api/myAPIName/create
      * @param {*} requestURL 
      * @param {*} request 
      */
     static parseRequestURL = (requestURL: string, request: {
          [key: string]: string | undefined
     }) => {
          Object.keys(request).forEach(key => {
               const value = request[key];
               if (value) {
                    requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), value);
               }
          });
          return requestURL;
     }
}

const UnifiedAPIService: AxiosInstance & { setToken?: (accessToken: string) => void, useTeam?: (team: string | undefined) => void } = axios.create();
UnifiedAPIService.defaults.baseURL = process.env.REACT_APP_UNIFIED_API_URL;
UnifiedAPIService.defaults.headers.post['Content-Type'] = 'application/json';
UnifiedAPIService.setToken = (accessToken) => {
     UnifiedAPIService.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
};
UnifiedAPIService.useTeam = (team) => {
     UnifiedAPIService.defaults.params = {
          "teamId": team
     }
};

export default UnifiedAPIService;

