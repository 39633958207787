import { Box } from "@material-ui/core";
import React from "react";
import { MemberNotificationList } from "./MemberNotificationList";
import { SubscriptionNotificationList } from "./SubscriptionNotificationList";

export const NotificationList:React.FC = () => {

    return (
        <Box mt={2}>
            <MemberNotificationList/>
            <Box mt={3} />
            <SubscriptionNotificationList/>
        </Box>
    )
}