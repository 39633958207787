import { Button } from "@material-ui/core";
import React from "react";

export interface SubscriptionNavigatorProps {
    openSubscriptionPage: () => void ;
}

export const SubscriptionNavigator: React.FC<SubscriptionNavigatorProps> = ({openSubscriptionPage}) => {

    return (
        <Button variant="contained" color="primary" style={{textTransform: 'none'}} onClick={openSubscriptionPage}>Subscribe</Button>
    )
}