import React, { useCallback, memo, useContext, useState, useEffect, useRef } from 'react'
import { AccordionContent } from '../../../../legacy-components/form/components/accordion-form/AccordionForm';
import { FormattedMessage } from 'react-intl';
import { useProductPublishInfo, ProductPublishOption } from '../product-forms/ProductPublishInfo';
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, makeStyles, createStyles, Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ProductPublishAction from '../product-forms/ProductPublishAction';
import PropTypes from "prop-types";
import ProductService, { ProductEnvironmentType, IAPIProduct } from '../../../../libs/resources/product/ProductService';
import { ProductViewContext } from '../ProductView';
import clsx from 'clsx';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import ProductEnvironmentConfirmationDialog, { ProductEnvironmentConfirmationDialogRef } from '../product-forms/environment-configs/ProductEnvironmentConfirmationDialog';
import NAWrap from '../../../../legacy-components/form/action/NAWrap';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import { StatusBadge } from 'legacy-components/badges/StatusBadge';
import { ProductApprovalTypeEdit } from '../product-forms/ProductApprovalTypeEdit';
import Alert from '@material-ui/lab/Alert';
import FeatureAbility, { Feature } from 'libs/security/authorization/FeatureAbility';
import { useFeatureAbility } from 'libs/security/authorization/Permission';

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        secondaryAction: {
            right: "0"
        },
        iconSuccess: {
            color: palette.success.light
        }, title: {
            padding: spacing(0, 0, 3, 0),
        }
    }),
);

type PublishActions = "publish" | "unpublish";

interface PublishOptionProps {
    onSuccess: (option: ProductPublishOption, action: PublishActions) => void,
    option: ProductPublishOption,
    secondaryActionStyle: string,
    product: IAPIProduct,
    env: ProductEnvironmentType
}

interface ProductPublishViewProps {
    product: IAPIProduct;
}

interface ProductUnPublishActionProps {
    onSuccess: () => void,
    productName: string,
    env: ProductEnvironmentType,
    isPromoted: boolean;
}

const ProductUnPublishAction = ({ productName, env, isPromoted, onSuccess }: ProductUnPublishActionProps) => {
    const confirmDialogRef = useRef<ProductEnvironmentConfirmationDialogRef>();
    const [enablePromote, setEnablePromote] = useState(isPromoted);
    const onUnpublish = useCallback(() => confirmDialogRef?.current?.open(), [confirmDialogRef])
   
    const hasFeature = useFeatureAbility({
        id: Feature.PartnerMarketplace
    })
    const promoteMessage = (
        <FormattedMessage id="promote" defaultMessage={"Promote"} />
    );

    const demoteMessage = <FormattedMessage id="demote" defaultMessage={"Demote"} />;
    const message = enablePromote ? demoteMessage : promoteMessage;
    const [buttonMessage,setButtonMessage] = useState(message);
    const notificationContext = useContext(SnackNotificationContext);
    const PromotedNotification = () => {
        return (
            <FormattedMessage defaultMessage="Product Promoted to Partner Marketplace" />
        )
    }

    const DemotedNotification = () => {
        return (
            <FormattedMessage defaultMessage="Product Demoted from Partner Marketplace" />
        )
    }
    
    const onPromote = () => {
        return new Promise<void>((resolve) => {
            if(buttonMessage.props.id == "promote") {
                ProductService.promoteProductToPartnerMarketplace(productName).then(() => {
                    notificationContext.show({
                        id: "promoted",
                        message: <PromotedNotification />,
                        timeout: 5000

                    });
                    resolve();
                }, error => {
                        notificationContext.show({
                            id: "promoted",
                            message: error,
                            timeout: 5000
                    });
                })
            }
            else if(buttonMessage.props.id == "demote") {
                ProductService.demoteProductToPartnerMarketplace(productName).then(() => {
                    notificationContext.show({
                        id: "demoted",
                        message: <DemotedNotification />,
                        timeout: 5000

                    });
                    resolve();
                }, error => {
                        notificationContext.show({
                            id: "demoted",
                            message: error,
                            timeout: 5000
                    });
                })
            }
        })
    }
    return (
        <>
            <ProductEnvironmentConfirmationDialog ref={confirmDialogRef} onSubmit={onSuccess} productName={productName} env={env} />
            <ActionInliner padding="inset">
                {env == "trimble-prod" && hasFeature &&
                    <Button color='primary' variant='contained' onClick={onPromote}>{buttonMessage}</Button>}
                <Button disabled={true} variant="outlined" onClick={onUnpublish}><FormattedMessage defaultMessage="Unpublish" /></Button>
            </ActionInliner>
        </>
    )
}

const PublishOption = memo(({ env, option, onSuccess, secondaryActionStyle, product }: PublishOptionProps) => {
    const styles = useStyles();
    const productViewContext = useContext(ProductViewContext);

    const [published, setPublished] = useState(() => productViewContext.state?.publishStatus?.[env]);

    useEffect(() => {
        setPublished(productViewContext.state?.publishStatus?.[env])
    }, [productViewContext])

    const onPublish = useCallback(() => onSuccess(option, "publish"), [onSuccess, option]);
    const onUpublish = useCallback(() => onSuccess(option, "unpublish"), [onSuccess, option]);

    const onUpdate = useCallback((data) => {
        productViewContext.dispatch({
            type: "productUpdate",
            payload: {
                product: data
            }
        })
    }, [productViewContext]);
    return (

        <>
            <ListItem key={option.value} disableGutters>
                <ListItemIcon>
                    {published?.status === "SUCCEEDED" ?
                        <FiberManualRecordIcon color="disabled" className={clsx({
                            [styles.iconSuccess]: true
                        })} />
                        :
                        <FiberManualRecordIcon color="disabled" className={clsx({
                            [styles.iconSuccess]: false
                        })} />
                    }
                </ListItemIcon>
                <ListItemText primary={option.title} secondary={option.helperText} />
                {published?.approvalType &&
                    <ListItemText>
                        <StatusBadge align="left" status={{
                            kind: "access",
                            key: published?.approvalType
                        }} />
                    </ListItemText>}
                <ListItemSecondaryAction className={secondaryActionStyle}>
                    <ProductApprovalTypeEdit published={published} product={product} onSuccess={onUpdate} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem key={option.value + "button"} disableGutters>
                <AccordionContent>
                    {published?.status === "SUCCEEDED" ? <ProductUnPublishAction env={option.env} productName={product.name} onSuccess={onUpublish} isPromoted={product.isPromoted} /> : <ProductPublishAction env={option.env} productName={product.name} onSuccess={onPublish} />}
                </AccordionContent>
            </ListItem>
        </>
    );
});

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Product Published" />
    )
}

const ProductPublishViewer = ({ product }: ProductPublishViewProps) => {
    const productViewContext = useContext(ProductViewContext);
    const notificationContext = useContext(SnackNotificationContext);

    const styles = useStyles();
    const { defaultPublishOptions } = useProductPublishInfo({
        useShortMessage: true
    });


    const onSuccess = useCallback((option: ProductPublishOption, action: PublishActions) => {
        notificationContext.show({
            id: "product-view-publish",
            message: <NotificationMessage />
        });

        productViewContext.dispatch({
            type: "publishUpdate",
            payload: {
                env: option.env,
                publishedGateway: {
                    gatewayName: option.env,
                    status: action === "publish" ? "SUCCEEDED" : null,
                }
            }
        })
    }, [productViewContext, notificationContext]);

    return (
        <List>
            {
                defaultPublishOptions.map(option => (
                    <PublishOption product={product} key={option.value} env={option.env} secondaryActionStyle={styles.secondaryAction} onSuccess={onSuccess} option={option} />
                ))
            }
        </List>
    );
}

function ProductPublishView({ product }: ProductPublishViewProps) {
    const styles = useStyles();
    const [] = useState(false);
    return (
        <AccordionContent size="small" header={<div>

            <FormattedMessage defaultMessage="Publish" />

        </div>
        } >
            <div className={styles.title}>
                <Alert severity="info">
                    <FormattedMessage defaultMessage="Publish the product along with the request approval option. Default approval option will be open." />
                </Alert>
            </div>
            <NAWrap value={product.visibility} fallback={<FormattedMessage defaultMessage="Product visibility option should not be empty" />}>
                <ProductPublishViewer product={product} />
            </NAWrap>
        </AccordionContent>
    )
}

ProductPublishView.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
}

export default memo(ProductPublishView);

