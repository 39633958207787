import React from 'react';
import { createStyles, Link, List, ListItem, ListItemText, makeStyles, Theme } from "@material-ui/core";
import CopyrightIcon from '@material-ui/icons/Copyright';
import DoNotSellIcon from 'legacy-components/icons/DoNotSellIcon';
import PrivacyIcon from 'legacy-components/icons/PrivacyIcon';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
      marginBottom: 0,
      marginTop: 'auto',
      bottom: 0,
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
          width: '100%',
          boxShadow:'2px -3px 6px -1px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)'
      }
  },
  list: {
    textAlign: 'inherit'
},
trimbleLinks: {
    margin: 'auto',
    padding: 0
},
trimbleLink: {
    width : 'auto',
    color: '#555',
    fontSize: '0.85rem',

    [theme.breakpoints.down('sm')]: {
        textAlign: "center"
    },
    '&:hover,&:focus, &:visited' : {
        color: '#333'
    }
},
trimbleLinkText: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'inline'
    }
},
trimbleLinkImage: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
        display: 'inline'
    }
}
}),
);
const Footer = () => {
    const classes = useStyles();
    const trimbleLinks = [
        {
            title: (<> &copy; Copyright 2025, Trimble Inc.</>),
            image:(<CopyrightIcon/>),
            link: "http://www.trimble.com/copyrights.aspx"
        },
        {
            title: "Terms of Use",
            image:(<DescriptionIcon/>),
            link: "https://www.trimble.com/Corporate/Terms_of_Use.aspx"
        },
        {
            title: "Privacy Statement",
            image:(<PrivacyIcon/>),
            link: "https://www.trimble.com/Corporate/Privacy.aspx"
        },
        {
            title: "Do Not Sell My Personal Information",
            image:(<DoNotSellIcon/>),
            link: "https://www.trimble.com/Corporate/Privacy.aspx?tab=contact_us"
        }
    ]
    return(
        <footer className={classes.root}>
            <List className={"flex-row-center " + classes.trimbleLinks}>
                {trimbleLinks.map((trimbleLink: any) => (
                    <ListItem key={trimbleLink.title} className={classes.trimbleLink}>
                        <ListItemText
                        primary={<Link color="primary"  className={classes.trimbleLinkText}
                        href={trimbleLink.link} target="_blank" rel="noopener noreferrer">{trimbleLink.title}</Link>}
                        />
                        <ListItemText
                        primary={<Link color="primary"  className={classes.trimbleLinkImage}
                        href={trimbleLink.link} target="_blank" rel="noopener noreferrer">{trimbleLink.image}</Link>}
                        />
                    </ListItem>
                    )
                    )
                }
            </List>
        </footer>
    );
}

export default Footer;
