import React, { Suspense } from 'react'
import { withRouter } from 'react-router';
import { GenericPageHeaderSkeleton } from 'legacy-components/page-headers/GenericPageHeader';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'themes/mui';
import NotificationDiscoveryPage from './pages/NotificationDiscoveryPage';


const NotificationRoutes = () => {
    return (
        <ThemeProvider theme={theme}>
        <Suspense fallback={<GenericPageHeaderSkeleton />}>
            <NotificationDiscoveryPage/>
        </Suspense>
        </ThemeProvider>
    )
}


export default withRouter(NotificationRoutes)

