import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { from } from "rxjs";
import { Product } from "pages/home/products/components/ProductStackview";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { ProductDetail } from "pages/home/products/components/Product.model";
import GuestClient from "./GuestClient";

@APIResource(GuestClient, "")
class GlobalProductServiceImpl {
    @APIMethod("/products", {
        options: {
          noTrailingSlash: true
      }
      })
      public getGlobalProductList() {
        return from(
            GuestClient.get<Product[]>(apiMethodContext(arguments)));
      }
    
      @APIMethod("/products/:productId", {
        options: {
          noTrailingSlash: true
      }
      })
      public getGlobalProduct(productId: string) {
        return from(
            GuestClient.get<ProductDetail>(apiMethodContext(arguments), {
            params: {
              productId: productId
            },
          }));
      }
}

export const GlobalProductService = new GlobalProductServiceImpl();

