import { Box } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

export const ConsoleSeperationBanner: React.FC = () => {

    const [ isShowBanner, setIsShowBanner ] = useState<boolean>(true);
    return (
        <>
        {(isShowBanner && process.env.REACT_APP_STAGE_URL) && (<Box style={{
            backgroundColor: "#0063A3",
            height: "40px",
            color:"#fff",
            display:"flex",
            justifyContent: "center",
            position: "relative",
       }}><Box style={{
            backgroundColor: "#0063A3",
            height: "40px",
            color:"#fff",
            display:"flex",
            alignItems: "center",
            alignSelf: "center"
       }}>
            <div>Pre-Production entities will be accessed via <a style={{ color:"#fff", textDecoration:"underlined"}} 
            href={process.env.REACT_APP_STAGE_URL} target="_blank">{process.env.REACT_APP_STAGE_URL}</a>
            <CloseIcon style={{cursor: "pointer", marginRight: "10px", position: "absolute", right: 0}} onClick={() => setIsShowBanner(!isShowBanner)} />
            </div>
            </Box>
            </Box>)}
        </>
    )
}