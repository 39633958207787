import Footer from "legacy-components/footer/Footer";
import React from "react";
import GuestHeader from "./GuestHeader";
import { Box, Button, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { GuestRoutes } from "./routes";
import AssociateTeam from "./components/AssociateTeam";
import { ConsoleSeperationBanner } from "pages/home/ConsoleSeperationBanner";

const consoleFeatures = [
    "Seamlessly integrate Trimble Identity for secure sign-ins",
    "Consume APIs and events to enhance your applications",
    "Publish your own APIs for broader connectivity"
  ];

export const ConsoleFeatures = () => {

    const user = useSelector((state: any) => state.userManager);
    let history = useHistory();

    return (
        <Box p={2}>
          <Paper >
              <Box p={3}>
              <Typography variant="h6" variantMapping={{h4: ""}} style={{fontWeight: 500}}>Welcome {user.firstName} {user.lastName},</Typography>
              <List>
                {consoleFeatures.map(( feature, index) => (
                  <ListItem key={index}>
                  <ListItemAvatar style={{marginBottom: "-9px"}}>
                      <CheckCircle style={{color: "#006638", fill :"006638"}}  />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="body1" style={{fontWeight: 500}}>{feature}</Typography>
                  </ListItemText>
                  </ListItem>)
                )}
              </List>
              <Typography variant="body1" style={{fontWeight: 500}}>
                    Explore the features by joining an existing team in your account or creating a new one!
              </Typography>
              <Box py={3}>
                    <Box display="flex" flexDirection="row">
                        <AssociateTeam />
                        <Box mx={1} />
                        <Button onClick={()=>{history.push(`/guest/products`)}} color="secondary" variant="outlined">Explore Products &gt;&gt; </Button>
                    </Box>
              </Box>
              </Box>
          </Paper>
        </Box>
    )
}
export const GuestHome:React.FC = () => {
    return (
        <React.Fragment>
            <GuestHeader/>
            <ConsoleSeperationBanner/>
            <main>
                <section className="main-content">
                    <div className="home-section">
                        <GuestRoutes />
                    </div>
                    <Footer />
                </section>
            </main>
        </React.Fragment>
    );
}