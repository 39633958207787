import { Badge, Box, Link } from "@material-ui/core";
import React from "react";
import { INotification, useNotificationListController } from "./NotificationList.controller";
import Accordion from "modus/components/misc/Accordion";
import MemberRequestIcon from "legacy-components/icons/MemberRequestIcon";
import { AsyncActionState } from "modus/components/form/async-actions/AsyncAction.type";
import Loader from "modus/components/misc/Loader";
import { ErrorMessage, resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import SubscriptionRequestIcon from "legacy-components/icons/SubscriptionRequestIcon";
import { getByText, getColor, getElapsedTime } from "./MemberNotificationList";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import SubscriptionNotificationActions from "./NotificationActions/SubscriptionNotificationActions";

export const SubscriptionNotificationList:React.FC = () => {

    const { asyncResponse ,subscribe } = useNotificationListController({type:"SUBSCRIPTION"});
    const { open , toggle} = useDialogProps(true);

    return (
        <Box>
            <Accordion 
                icon={<SubscriptionRequestIcon color="primary"/>}
                title={"Subscription Request"} 
                expanded={open}
                onClick={toggle}
                className="notification-accordion"
                details={<SubscriptionNotificationListResponse response={asyncResponse} refreshList={subscribe}/>}
            />
        </Box>
    )
}

interface SubscriptionNotificationListResponse {
    response?:   AsyncActionState<INotification[] | null | undefined>; 
    refreshList?: () => void;
}
export const SubscriptionNotificationListResponse:React.FC<SubscriptionNotificationListResponse> = ({response, refreshList}) => {
    return (
        <Box style={{width:"calc(100% - 30px)"}}>
           {response?.loading && <Loader/>}
           {response?.error && <ErrorMessage message={resolveAPIErrorMessage(response.error)} />}

           {response?.loaded && response?.value && response?.value?.length === 0 && <ErrorMessage message={"No pending approvals"} />}
           {response?.loaded && response?.value && response?.value?.length > 0 && (
            response?.value?.map((notification: INotification) => (<SubscriptionNotificationCard notification={notification} refreshList={refreshList} />))
           )}
        </Box>
    );
};
interface SubscriptionNotificationCardProps {
    notification: INotification;
    refreshList?: () => void;
}
export const SubscriptionNotificationCard:React.FC<SubscriptionNotificationCardProps> = ({notification, refreshList}) => {
    const currentTeam  = useSelector((state: any) => state?.teamManager?.teams?.currentTeam?.teamName ??  sessionStorage.getItem(MY_SELECTED_TEAM_));
    const history = useHistory();
    const elapsedTime  = getElapsedTime(notification.requestDate);
    return (
        <Box className="notification-card">
            <SubscriptionRequestIcon color="primary"/>
            <Box width="100%">
                <Box>
                <Link href={`mailto:${notification?.requestedBy?.emailAddress}`} target="_blank">{notification?.requestedBy?.emailAddress} </Link>
                {notification?.requestedBy?.accountName && <span>from <b><i>{notification?.requestedBy?.accountName}</i></b> </span>} 
                &nbsp;wants to subscribe the application&nbsp;
                <b><i>{notification?.resource?.verificationInfo?.applicationName}</i></b> to&nbsp;
                <Link onClick={() => history.push(`/home/teams/${currentTeam}`)}>{notification?.resource?.verificationInfo?.productName}</Link>.
                </Box>
                <Box
                    style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    }}
                >
                    <Badge className={`badge ${getColor(elapsedTime) }`}>{getByText(elapsedTime) }</Badge>
                    <SubscriptionNotificationActions notification={notification}refreshList={refreshList} />
                </Box>
            </Box>
           
        </Box>
    );
}