import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import * as actions from '../store/actions/actions';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { IconButton, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from 'services/authService';
import { useRole } from 'libs/security/authorization/Permission';
import Loader from 'modus/components/misc/Loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
        zIndex: 100,
        width: '20vw'
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    accountButton: {
        margin: 'auto'
    },
    userName: {
        textAlign: "center",
        margin: "15px"
    },
    menuList: {
        marginTop: '20px',
        borderTop: '1px solid #e8eaed'
    },
    userPanelRoot: {
        display: "flex",
        flexDirection: "column" 
    },
    role: {
      textAlign: "center"
    }
  }),
);

const UserPanel = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rolepermission] = useRole();
  const  id_token = useSelector((state: any) => state.auth?.user?.id_token);
  const  access_token = useSelector((state: any) => state.auth?.user?.access_token);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const history =  useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userManager);
  const handleToggle = () => {
    setOpen((isPrevOpen) => !isPrevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const openProfile = () => {
        handleClose();
        history.push("/home/profile");  
  }
  const signOut = () => {
    handleClose();
    dispatch({ type: actions.clearUserDetails});
    AuthService.signOutRedirect(id_token);
}

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
        <>
          
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            data-testid="user-panel-btn"
          ><AccountCircleOutlinedIcon /></IconButton>
        <Popper open={open} anchorEl={anchorRef.current} className={classes.popper} transition disablePortal
          placement="top-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={classes.userPanelRoot}>
                        <Typography variant="h6" className={classes.userName}>
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom className={classes.role}>
                          {rolepermission?.role}
                        </Typography>
                        <Typography variant="body2" className={classes.userName}>
                            {user.email}
                        </Typography>
                       
                        <Link href={process.env.REACT_APP_USER_PROFILE_UI_URL + '?access_token='+ access_token} className="link-button"
                        target="_blank" rel="noopener noreferrer" data-testid = "myprofile-btn">
                          My Profile
                        </Link>
                        <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList} onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={openProfile} data-testid="accesskeys-btn">Access Keys</MenuItem>
                            <MenuItem onClick={signOut} data-test="logout-btn">Logout</MenuItem>
                        </MenuList>
                    </div>
                    
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </>
  );
}

export  default UserPanel;
