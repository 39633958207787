import { AxiosResponse } from "axios";
import { AsyncActionState, useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Controller } from "modus/utilities/ViewController";
import { useHistory } from "react-router";
import { ProductDetail } from "pages/home/products/components/Product.model";
import { GlobalProductService } from "pages/home/consumer/service/GlobalProductService";

export interface GlobalProductDetailModel {
  response: AsyncActionState<AxiosResponse<ProductDetail> | null>;
  subscribe?: (stream?: boolean) => () => void;
}

export const useGlobalProductDetailsController:Controller<{},GlobalProductDetailModel> = () => {
  const {productId} = useParams<any>();
  const [response, {subscribe}] = useAsyncAction(() =>  GlobalProductService.getGlobalProduct(productId));
  const history = useHistory();

  useEffect(() => {
    if (!productId || !subscribe) {
      return;
    }

    subscribe();
  }, [productId, subscribe]);

  useEffect(() => {
    if (
      !response.value?.data.identityEnvs ||
      !history
    ) {
      return;
    }
  }, [history, response.value]);

  return {
    response,
    subscribe
  };
};
