import { Box } from "@material-ui/core";
import { ProductStackCard } from "./ProductStackview";
import { AsyncActionState } from "legacy-components/form/action/AsyncAction";
import React from "react";
import { withViewController } from "modus/utilities/ViewController";
import { useProductListController } from "./ProductList.controller";
import { IProduct } from "pages/home/consumer/service/ProductService";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";
import {
  APIErrorMessage,
  ErrorMessage,
} from "modus/components/error-message/ErrorMessage";

export interface ProductListViewModel {
  asyncResponse?: AsyncActionState<AxiosResponse<IProduct[]> | null> | undefined;
  filteredProducts?: IProduct[];
  isGuest?: boolean;
}

interface StackSkeletonProps<Type> {
  response?: AsyncActionState<Type>;
  count: number;
}
export const StackSkeleton = <Type,>({
  response,
  count = 4,
}: StackSkeletonProps<Type>) => (
  <>
    {response?.loading &&
      new Array(count).fill(1).map((num, index) => (
        <Box mb={"2px"} key={index}>
          <Skeleton
            animation="wave"
            variant="rect"
            height={"70px"}
            width="100%"
          />
        </Box>
      ))}
  </>
);
export const ProductListView: React.FC<ProductListViewModel> = ({
  asyncResponse,
  filteredProducts,
  isGuest
}) => {
  return (
    <Box mt={2} maxHeight={`calc(100vh - 250px)`} style={{ overflowY: "auto" }}>
      <StackSkeleton count={8} response={asyncResponse} />

      {asyncResponse?.loaded &&
        asyncResponse?.value?.data &&
        filteredProducts &&
        filteredProducts?.length > 0 &&
        filteredProducts?.map((product: IProduct) => {
          return product && <ProductStackCard {...product} isGuest={isGuest} />;
        })}
      {asyncResponse?.loaded &&
        asyncResponse?.value?.data &&
        filteredProducts &&
        filteredProducts?.length === 0 && (
          <ErrorMessage message={"No matching products found"} />
        )}
      {asyncResponse?.error && (
        <APIErrorMessage error={asyncResponse?.errorValue} />
      )}
    </Box>
  );
};

export const ProductList = withViewController(
  ProductListView,
  useProductListController
);
