import { MY_SELECTED_TEAM_ } from "header/team-selector/TeamSelector";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import TeamService, { IAPITeam } from "pages/home/teams/services/TeamService";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Observable } from "rxjs";

export const useMemberListController = () => {
    const currentTeam = useSelector((state: any) => state?.teamManager?.teams?.currentTeam?.teamName ?? sessionStorage.getItem(MY_SELECTED_TEAM_));
    
    const {state, subscribe} = useAsyncAction(() => TeamService.getTeamMembers(currentTeam) as Observable<IAPITeam[]>);


    useEffect(() => {
        if(!subscribe || !currentTeam) {
            return;
        }
        subscribe();
    },[subscribe, currentTeam]);
    return {
        state,
        subscribe
    };
}