import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router';
import CloudConsoleLogo from 'legacy-components/icons/CloudConsoleLogo';
import CompanyLogo from 'legacy-components/icons/CompanyLogo';
import { useSelector } from 'react-redux';
import MainHelper from 'pages/home/helper/MainHelper';
import { useFeatureToggler } from 'libs/utils/generic/ConsoleFeatureToggler';
import { RelatedWebApps } from 'header/RelativeApps';
import Notification from 'header/Notification';
import LanguageMenu from 'header/LanguageMenu';
import UserPanel from 'header/UserPanel';
import { EnvironmentSelector } from 'header/environment-selector/EnvironmentSelector';
import TeamSelectorUserOnBoarding from 'header/team-selector/TeamSelectorUserOnboarding';
import GuestClient from 'pages/home/consumer/service/GuestClient';
import { CloudConsoleClient } from 'libs/utils/http/client/HttpClient';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 0,
      width: "inherit"
    },
    appBar: {
      background: "#fff",
      color: "#000",
      userSelect: "none"
    },
    toolBar: {
      width: "inherit",
      margin: 0,
      minHeight: '56px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    searchDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      }
    },
    settings: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },

  }),
);

interface HeaderProps {
  toggleNav?: () => void
  toggleNavSize?: () => void
}
const GuestHeader: React.FC<HeaderProps> = ({ toggleNav, toggleNavSize }) => {
  const classes = useStyles();
  const [openHelper, setOpenHelper] = useState(false);
  const history = useHistory();
  const { isLegacyFeatureUrl } = useFeatureToggler();
  const onHelpClicked = () => {
    setOpenHelper(true);
  }

      let accessToken = useSelector((state: any) => state.auth?.user?.access_token);
  
      useEffect(()=>{
        GuestClient.useToken?.(accessToken);
        CloudConsoleClient.useToken?.(accessToken);
      },[accessToken]);
 

  const toggleNavigationPanel = () => {
    toggleNavSize && toggleNavSize();
  }
  const toggleMobileNavigationPanel = () => {
    toggleNav && toggleNav();
  }
  let search;
  // search = (<SearchReceiver className={classes.searchDesktop}/>);
  let settings = (
    <div className={classes.settings} >
      <RelatedWebApps/>
      <IconButton onClick={onHelpClicked}>
        <HelpOutlineIcon />
      </IconButton>
      {/*<Notification />*/}
      <MainHelper open={openHelper} handleClose={() => { setOpenHelper(false) }} />
      <LanguageMenu />
      <IconButton><AppsOutlinedIcon /></IconButton>
      <UserPanel />
    </div>
  );
  /**
   * Hiding Search and Settings temporarily until features are implemented
   */
  settings = (
    <div className={classes.settings} >
      <RelatedWebApps/>
      {/*<Notification />*/}
      <IconButton onClick={onHelpClicked}>
        <HelpOutlineIcon />
      </IconButton>
      <MainHelper open={openHelper} handleClose={() => { setOpenHelper(false) }} />
      <UserPanel />
    </div>
  );
  sessionStorage.removeItem('MY_SELECTED_TEAM_');
  sessionStorage.removeItem('MY_TEAM_KEY');


  return (
    <div className={classes.root}>
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              className="desktop-view"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNavigationPanel}
              style={{ visibility: 'hidden' }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              edge="start"
              className="mobile-view"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleMobileNavigationPanel}
            >
              <MenuIcon />
            </IconButton>
            <CompanyLogo />
            <CloudConsoleLogo  />
            <TeamSelectorUserOnBoarding/>
            <span className={classes.title} />
            {search}
            {settings}
          </Toolbar>
        </AppBar>
    </div>
  );
}


export default GuestHeader;
