import { APIResource, APIMethod } from "../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../utils/http/client/HttpClient";
import { from } from "rxjs";
import { apiMethodContext } from "../../utils/http/builder/UrlConfig";



export interface IAPIProductSubscription {
    subscriptionId?: string,
    applicationName: string,
    subscriberEmail: string,
    subscriptionRequestedTime?: string,
    subscription: any,
    status: string,
    teamName: string,
    gatewayName: string,
    sectorName: string,
    consumerKey: string
}

export interface IAPIApplicationSubscription {
    applicationName: string,
    status: string,
    productName: string,
    gatewayName: string,
    consumerKey: string,
    subscriptionId: string,
    subscriptionRequestedTime: string,
    subscriberEmail: string,
    productTeamName: string,
    productId: string
}

@APIResource(CloudConsoleClient, "publisher")
class SubscriptionServiceFactory {

    @APIMethod("/teams/subscriptions")
    public getTeamSubScriptions(filterSelf: boolean, gateway: string) {
        return from(CloudConsoleClient.get<IAPIApplicationSubscription[]>(apiMethodContext(arguments), {
            params: {
                filterSelf,
                gatewayName:gateway
            }
        }).then(value => value.data));
    }

    @APIMethod("/applications/:applicationName/gateway/:gatewayName/subscriptions")
    public getApplicationSubScriptions(applicationName: string, gatewayName: string) {
        return from(CloudConsoleClient.get<IAPIApplicationSubscription[]>(apiMethodContext(arguments), {
            params: {
                applicationName,
                gatewayName
            }
        }).then(value => value.data));
    }
}

const SubscriptionService = new SubscriptionServiceFactory();

export default SubscriptionService;