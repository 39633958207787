import {
    APIResource,
    APIMethod,
  } from "../../../../libs/utils/http/decorator/UrlDecorator";
  import { CloudConsoleClient } from "../../../../libs/utils/http/client/HttpClient";
  import { apiMethodContext } from "../../../../libs/utils/http/builder/UrlConfig";
  import { from } from "rxjs";
  
  @APIResource(CloudConsoleClient)
  class SubscriptionApprovalServiceFactory {
    @APIMethod("/publisher/v2/approvals/approve", {
      options: {
        noTrailingSlash: true,
      },
    })
    public approveSubscriptionRequest(subscriptionId: string) {
      return from(
        CloudConsoleClient.put<any>(
          apiMethodContext(arguments),
          {
            resourceType: "SUBSCRIPTION",
            resourceName: subscriptionId
          },
          {
            params: {
              type: "SUBSCRIPTION",
              request: "REVIEW",
            },
          }
        )
      );
    }
  
    @APIMethod("/publisher/v2/approvals/deny", {
      options: {
        noTrailingSlash: true,
      },
    })
    public denySubscriptionRequest(subscriptionId: string, reason: string) {
      return from(
        CloudConsoleClient.put<any>(
          apiMethodContext(arguments),
          {
            resourceType: "SUBSCRIPTION",
            resourceName: subscriptionId,
            reason: reason,
          },
          {
            params: {
              type: "SUBSCRIPTION",
              request: "REVIEW",
            },
          }
        )
      );
    }
  }
  const SubscriptionApprovalService = new SubscriptionApprovalServiceFactory();
  
  export default SubscriptionApprovalService;
  