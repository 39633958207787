import {
  APIResource,
  APIMethod,
} from "../../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";

@APIResource(CloudConsoleClient)
class TeamApprovalServiceFactory {
  @APIMethod("/publisher/v2/approvals/approve", {
    options: {
      noTrailingSlash: true,
    },
  })
  public approveTeamRequest(teamId: string, role: string) {
    return from(
      CloudConsoleClient.put<any>(
        apiMethodContext(arguments),
        {
          resourceType: "TEAM",
          resourceName: teamId,
          role: role,
        },
        {
          params: {
            type: "TEAM",
            request: "REVIEW",
          },
        }
      )
    );
  }

  @APIMethod("/publisher/v2/approvals/deny", {
    options: {
      noTrailingSlash: true,
    },
  })
  public denyTeamRequest(teamId: string, reason: string) {
    return from(
      CloudConsoleClient.put<any>(
        apiMethodContext(arguments),
        {
          resourceType: "TEAM",
          resourceName: teamId,
          reason: reason,
        },
        {
          params: {
            type: "TEAM",
            request: "REVIEW",
          },
        }
      )
    );
  }
}
const TeamApprovalServices = new TeamApprovalServiceFactory();

export default TeamApprovalServices;
