import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';/**
 * @class HomeRoutes
 * @description For storing internal page redirection inside Home Module
 */
import { useSelector } from 'react-redux';
import GuestProductSummary from './products/GuestProductSummary';
import GuestHomePage from './GuestHomePage';
import GuestProduct from './products/GuestProduct';
export const GuestRoutes: React.FC = () => {

    let isLoggedIn = useSelector((state: any) => state.auth?.user?.access_token? true: false);
     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     
          return (
               <Switch>
                    <Route exact path="/guest/products/:productId" render={() => (<GuestProductSummary />)} />
                    <Route exact path="/guest/products" render={() => (<GuestProduct />)} />
                    <Route exact path="/guest/home" render={() => (<GuestHomePage />)} />
                    <Route render={() => (isLoggedIn?<Redirect to="/guest/home"/>:<Redirect to="/login"/> )}  />
               </Switch>
          );
}

export default withRouter(GuestRoutes);
