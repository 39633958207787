
  import { from } from "rxjs";
  import { sanitize } from "dompurify";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { TeamDetail } from "pages/home/team/models/team.model";
  
  export interface IAPITeam {
    id: string;
    name: string;
    email: string;
    team: string;
    role: string;
  }
  export interface User {
    id: string;
  }
  export interface Team {
    id: string;
    teamName: string;
  }
  export interface IUserTeam {
    user: User;
    team: Team;
  }
  
  export interface InviteRequest {
    name?: string;
    emailAddress?: string;
    team?: string;
    role?: string;
  }
  
  export interface Context {
    id: string;
    name: string;
    context: string;
  }
  
  export interface Namespace {
    apiPublishLimit: number;
    baCode: string;
    contexts: Array<Context>;
    email: string;
    nameSpaces: Array<string>;
    productPublishLimt: number;
    sector: string;
    teamId: string;
    teamName: string;
  }
  
  @APIResource(CloudConsoleClient)
  class TeamServiceFactory {
  
    @APIMethod("/publisher/user/teams", {
      options: {
        noTrailingSlash: true,
      },
    })
    public getUsersTeam() {
      return from(
        CloudConsoleClient.get<any>(apiMethodContext(arguments), {
        }).then(
          (response:any) => response.data
        )
      );
    }


  @APIMethod("/publisher/user")
  public inviteUser(gateway: string | null, request: InviteRequest) {
    const context = apiMethodContext(arguments);

    return from (
      CloudConsoleClient.post(context, request, {
        params: {
          gateway,
        },
      })
      );
    };
  
  
    @APIMethod("/publisher/team/:teamname/users")
    public getTeamMembers(teamname: string) {
      return from(
        CloudConsoleClient.get<IAPITeam[]>(apiMethodContext(arguments), {
          params: {
            teamname,
          },
        }).then((response: any) => response.data)
      );
    }
  
    @APIMethod("/publisher/team/:teamname")
    public forTeamUpdate(teamname?: string, teamDetail?: TeamDetail) {
      return from(
        CloudConsoleClient.put<TeamDetail>(
          apiMethodContext(arguments),
          {
            description: sanitize(teamDetail?.description ?? ""),
            website: sanitize(teamDetail?.website??""),
            email: sanitize(teamDetail?.email ?? ""),
            support: sanitize(teamDetail?.support ?? ""),
            division: sanitize(teamDetail?.divisionName ?? ""),
            baCode: sanitize(teamDetail?.baCode ?? ""),
            displayName: sanitize(teamDetail?.displayName?? "")
          },
          {
            params: {
              teamname,
            },
          }
        ).then((response: any) => response.data)
      );
    }
  
    @APIMethod("/publisher/user/:userId")
    public removeUserFromTeam(userId: string, teamName: string) {
      return from(
        CloudConsoleClient.delete(apiMethodContext(arguments), {
          params: {
            userId,
            teamName,
          },
        }).then((response: any) => response.data)
      );
    }

    @APIMethod("/publisher/user/:email")
    public updateUser(email: string, role: string, teamName: string) {
      const formData = new FormData();
      formData.append("role", role);
      formData.append("teamName", teamName);
      return from(
        CloudConsoleClient.put(apiMethodContext(arguments),formData, {
          params: {
            email
          },
        }).then((response: any) => response.data)
      );
    }
  
  }
  const TeamService = new TeamServiceFactory();
  
  export default TeamService;