import { Box, Button, DialogTitle, ThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { FormattedMessage } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import Table, { TableColumn } from "legacy-components/table/Table";
import Badge from "legacy-components/badge/Badge";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import { Teamcreator } from "./TeamCreator";
import { theme } from "themes/mui";
import TeamServices from "../team/TeamService";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import { TeamUserAssociator } from "./TeamUserAssociator";

export interface TeamJoinerProps {
  refreshUserTeams: any
  userUuid: string
}
export const TeamJoiner: React.FC<TeamJoinerProps> = ({
  refreshUserTeams,
  userUuid
}) => {
  const { state, subscribe } = useAsyncAction(() =>
    TeamServices.getAvailableTeamsForUser()
  );
  
  const { open, toggle } = useDialogProps();  

  const Actions = (data: any) => {
    if (data?.data?.action?.toUpperCase() === "JOIN")
      return (
        <TeamUserAssociator action = "Join" teamId={data?.data?.teamId} userUuid={userUuid} refreshUserTeams={refreshUserTeams}/>
      );
    else
      return (
        <TeamUserAssociator action = "Notify again" teamId={data?.data?.teamId} userUuid={userUuid} refreshUserTeams={refreshUserTeams}/>
          
      );
  };

  const StatusBadge = ({ data }: any) => {
    if (data !== "Waiting For Approval") return <></>;
    else
      return (
        <Box style={{ justifyContent: "space-between" }}>
          <Badge color="primary" label={data?.status} />
        </Box>
      );
  };

  const getAvailableTeams = () => {
    subscribe();
    toggle();
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={getAvailableTeams}
        variant="outlined"
        color="primary"
      >
        <FormattedMessage defaultMessage="Join a Team" />
      </Button>
      
      <Dialog
        open={open}
        title="Join Team"
        onClose={toggle}
        fullWidth={true}
        style={{ padding: "24px 21px" }}
      >
        <ThemeProvider theme={theme}>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >

            <DialogTitle>
              <FormattedMessage defaultMessage="Join a team" />
            </DialogTitle>
          <Teamcreator refreshUserTeams={refreshUserTeams}/>
        </Box>

        <Box style={{ padding: "30px 30px 30px 30px" }}>
          <Table
            noRowsText={`No teams available to join`}
            rowData={state?.value}
            height="506px"
            pagination={false}
          >
            <TableColumn
              field="teamName"
              flex={2}
              cellRenderer="Title"
              headerName="Name"
              sortable
              unSortIcon={true}
              filter="agTextColumnFilter"
              filterParams={{
                suppressAndOrCondition: true,
                defaultOption: "contains",
                filterOptions: ["contains"],
              }}
            />
            <TableColumn
              field="relation.userRole"
              flex={2}
              sortable
              unSortIcon={true}
              headerName="Status"
              cellRendererFramework={StatusBadge}
            />

            <TableColumn
              flex={2}
              sortable
              unSortIcon={true}
              headerName="Actions"
              cellRendererFramework={Actions}
            />
          </Table>
          <Box mx={1} style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "end"}}>
                        <Button onClick={toggle} color="secondary" variant="outlined">Cancel</Button>
                        </Box>
        </Box>
        </ThemeProvider>

      </Dialog>
    </>
  );
};