import React from "react"
import { ProductDetailModel, useProductDetailsController } from "./ProductDetails.controller"
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle"
import { Box, Grid, Paper } from "@material-ui/core"
import { SubscriptionNavigator } from "pages/home/subscriptions/components/subscription-navigator/SubscriptionNavigator"
import { ProductServices } from "../ProductServices"
import { Skeleton } from "@material-ui/lab"
import { withViewController } from "modus/utilities/ViewController"
import { ErrorMessage } from "modus/components/error-message/ErrorMessage"
import { useHistory } from "react-router"

export const ProductDetailsViewer:React.FC<ProductDetailModel> = ({response}) => {
  const history = useHistory();
  const openSubscriptionPage = () => {
    history.push(`/home/consumer/products/${response.value?.data.id}/subscribe`);
}
    return (
        <>
      {response.loaded && response?.value?.data && (
        <>
          <GenericPageTitle
            title={<>{response?.value?.data?.displayName ?? response.value?.data?.name}</>}
            description={
              <>
                {response?.value?.data?.longDescription}
              </>
            }
            controls={
              <SubscriptionNavigator openSubscriptionPage={openSubscriptionPage}/>
            }
            noSubTitle
          />
          {response.value?.data?.services && (
            <ProductServices productName={response.value?.data?.name} services={response.value?.data?.services} />
          )}
          {(response.value?.data && (!response.value?.data?.services || response.value?.data?.services?.length === 0)) && (
            <ErrorMessage message={"No service found"} />
          )}
        </>
      )}
      {!response.loaded && (
        <>
          <GenericPageTitle noControls noSubTitle/>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box py={2}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={"56px"}
                  width="100%"
                />
              </Box>
            </Grid>
          </Grid>
          <Paper style={{ marginTop: "30px" }}>
            <Box p={2} style={{ width: "100%" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                height={"200px"}
                width="100%"
              />
            </Box>
          </Paper>
        </>
      )}
    </>
    );
}

export const ProductDetails = withViewController(ProductDetailsViewer, useProductDetailsController);