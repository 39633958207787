import { createClient } from "libs/utils/http/client/HttpClient";



export const GuestClient = createClient("GuestClient", {
    baseURL: `${process.env.REACT_APP_CONSUMER_API_URL}/unifiedportal/publisher/v2`,
    isTeamIdRequired: false
});


export default GuestClient;