import React, { FC, useContext, useState, useEffect, useCallback } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button, TextField } from '@material-ui/core';
import { useIntl, FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';
import { TeamDetail } from '../models/team.model';
import { AccordionContent } from 'legacy-components/form/components/accordion-form/AccordionForm';
import FormContainer from "../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../legacy-components/form/action/ActionInliner";
import FormTextInput from "../../../../legacy-components/form/input/FormTextInput";
import { FormApi, FORM_ERROR } from 'final-form';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import TeamService from "../TeamService";
import { useResourceAbility } from 'libs/security/authorization/Permission';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sanitize } from 'dompurify';

interface DetailFormProps {
    onCancel?: () => void,
    onSuccess?: (teamDetail?: any) => void
    teamDetail?: TeamDetail,
    divisions?: Array<{
        key?: string,
        value?: string
    }>,
}

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Team Details Saved Successfully" />
    )
}

const DetailForm: FC<DetailFormProps> = React.memo(({ teamDetail, divisions, onSuccess }) => {
    const [can,] = useResourceAbility({
        resource: ({ Team }) =>
            Team.delete
    });
    const notificationContext = useContext(SnackNotificationContext);
    const [businessUnit, setBusinessUnit] = useState<string>();

    const onSubmit = () => (data: any, form: FormApi) => {
        let divisionName: string | undefined;
        let baCode: string | undefined;
        if(otherOptionChoosen == true) {
            divisionName = data.divisionName;
            baCode = data.baCode;
        } 
        else if (divisionName == "" && baCode == "") {
            divisionName = teamDetail?.division?.name;
            baCode = teamDetail?.division?.baCode
        }
        else {
            divisionName = businessUnit && businessUnit.split(",")[0];
            baCode = businessUnit && businessUnit.split(",")[1];
       }
        
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                const teamDetails: TeamDetail = {
                    description: sanitize(data.description),
                    website: sanitize(data.website?? ""),
                    email: sanitize(data.email ?? ""),
                    support: sanitize(data.support),
                    teamName: sanitize(teamDetail?.teamName ?? ""),
                    divisionName: sanitize(divisionName ?? ""),
                    baCode: baCode,
                    displayName: sanitize(data.displayName??"")
                }
                TeamService.forTeamUpdate(teamDetail?.teamName, teamDetails).subscribe(() => {
                    notificationContext.show({
                        id: "team-details",
                        message: <NotificationMessage />
                    });
                    resolve();
                }, error => {
                    resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? "Error in saving team details"
                    });
                });
            }

        })
    }
    const [divisionAndCode, setDivisionAndCode] = useState<string>();
    
    useEffect(() => {
        if (teamDetail?.division && teamDetail.division.name && teamDetail.division.baCode) {
            setDivisionAndCode(`${teamDetail?.division?.name}, ${teamDetail?.division?.baCode}`);
        }
        else {
            setDivisionAndCode("Division, BA Code")
        }
    }, []);
    const intl = useIntl();
    const validator = useContext(ValidatorBuilderContext);
    const [otherOptionChoosen, setOtherOptionChoosen] = useState<boolean>(false);

    const FormComponents = (formRenderProps: FormRenderProps<any, {}>) => {
       
        return (
            <>
                <FormInputContainer title={
                    <FormattedMessage defaultMessage="Manage how your team information is displayed in the Developer Marketplace." />
                }>
                    <>
                        <FormInputContainer>
                        <Field
                            name="displayName"
                            type="text"
                            component={FormTextInput}
                            defaultValue = {teamDetail?.displayName}
                            label={intl.formatMessage({
                                defaultMessage: "Team Name"
                            })}

                            validate={validator.from({
                                required: true,
                            })}
                            disabled={can.update() ? false : true}
                        />
                        </FormInputContainer>
                        <FormInputContainer>
                        <Field
                            name="description"
                            type="text"
                            multiline
                            rows={5}
                            component={FormTextInput}
                            label={intl.formatMessage({
                                defaultMessage: "Description"
                            })}
                            placeholder={intl.formatMessage({
                                defaultMessage: "Tell us a little bit about your team"
                            })}
                            disabled={can.update() ? false : true}
                        />
                        </FormInputContainer>
                    </>
                </FormInputContainer >
                {divisions && divisionAndCode && (

                    <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Business Unit *" />} />}>
                        <Autocomplete
                            id="autocomplete"
                            options={divisions}
                            onInputChange={(event, newInputValue) => {
                                setBusinessUnit(newInputValue);
                                if(newInputValue == "MY DIVISION IS MISSING,000") {
                                    setOtherOptionChoosen(true);
                                }
                                else {
                                 setOtherOptionChoosen(false);
             
                                }
                            }}
                            defaultValue = {divisions?.filter(division =>
                                division.value === divisionAndCode)[0]}
                            getOptionLabel={(option) => option.key as string}
                            renderInput={(params) => <TextField
                                {...params}
                                label = "Business Unit"
                                variant="outlined" />}
                        />
                    </FormInputContainer>
                )}
                {otherOptionChoosen && (
                <>
               <FormInputContainer>
                    <Field
                        name="divisionName"
                        type="text"
                        component={FormTextInput}
                        defaultValue = {teamDetail?.division?.name != null ? teamDetail?.division?.name: teamDetail?.divisionName}
                        label={intl.formatMessage({
                            defaultMessage: "Division Name"
                        })}

                        validate={validator.from({
                            required: true,
                        })}
                    />
                   </FormInputContainer>
                   <FormInputContainer>

                    <Field
                        name="baCode"
                        type="text"
                        component={FormTextInput}
                        defaultValue = {teamDetail?.division?.baCode != null ? teamDetail?.division?.baCode : teamDetail?.baCode}

                        label={intl.formatMessage({
                            defaultMessage: "BA Code"
                        })}

                        validate={validator.from({
                            required: true,
                            maxLength : 3,
                            pattern: ({ Numeric }) => Numeric,
                            
                        })}
                    />
                </FormInputContainer>
                </>
                )}
                <FormInputContainer title={<Typography variant="subtitle2" children={
                    <FormattedMessage defaultMessage="Contact Information" />

                } />}>
                    <Field
                        name="website"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Website"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "http(s)://example.com"
                        })}

                        validate={validator.from({
                            required: false,
                            pattern: ({ HttpUrl }) => HttpUrl
                        })}
                        disabled={can.update() ? false : true}
                    />
                </FormInputContainer>

                <FormInputContainer>
                    <Field
                        name="email"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Email"
                        })}

                        validate={validator.from({
                            required: false,
                            pattern: ({ GenericEmail }) => GenericEmail
                        })}
                        disabled={can.update() ? false : true}
                    />
                </FormInputContainer>
                <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Support" />} />}>
                    <Field
                        name="support"
                        type="text"
                        multiline
                        rows={5}
                        component={FormTextInput}

                        placeholder={intl.formatMessage({
                            defaultMessage: "Provide support methods for API consumers"
                        })}
                        disabled={can.update() ? false : true}
                    />
                </FormInputContainer>
                <ActionInliner>
                    <Button disabled={formRenderProps.submitting || formRenderProps.pristine || (can.update() ? false : true) || !businessUnit} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Save" /></Button>
                </ActionInliner>
            </>);
    }
    return (
        <AccordionContent size="small">
            <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} prefillValues={teamDetail} />
        </AccordionContent>
    )
});

export default DetailForm;