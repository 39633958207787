import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import ModusIcon from "legacy-components/icons/ModusIcon";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import TeamServices from "pages/home/team/TeamService";
import { TeamListViewer } from "pages/home/teams/TeamListViewer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MY_SELECTED_TEAM_, MY_TEAM_KEY, useTeamStyles } from "./TeamSelector";
import * as actions from "../../store/actions/actions";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRole } from "libs/security/authorization/Permission";
import { useHistory } from "react-router";
import Loader from "modus/components/misc/Loader";

export default function TeamSelectorUserOnBoarding(props: any) {
  const teamClasses = useTeamStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    changeTeam(sessionStorage.getItem(MY_TEAM_KEY) as string);
    dispatch({
      type: actions.storeTeamDetails,
      payload: { currentTeam: sessionStorage.getItem(MY_TEAM_KEY) },
    });
  }, [dispatch]);

  const [selectedTeamName, setSelectedTeamName] = useState<string>(
    (sessionStorage.getItem(MY_SELECTED_TEAM_) as string) ?? "Select a Team"
  );

  const [, userRoleState, changeTeam] = useRole();

  const [isLoading, toggleLoading] = useState<boolean>(false);

  const { state, subscribe } = useAsyncAction(() =>
    TeamServices.getUsersTeamByUuid(userRoleState?.value?.uuid as string)
  );

  useEffect(() => {
    if (!subscribe || !userRoleState?.value?.uuid) {
      return;
    }
    subscribe();
  }, [userRoleState, subscribe]);

  const { open, toggle } = useDialogProps();
  const onClick = () => {
    toggle();
  };
  const history = useHistory();

  const handleChangeNew = (team: any) => {
    toggleLoading(!isLoading);
    changeTeam(team?.id as string);
    sessionStorage.setItem(MY_TEAM_KEY, team?.id as string);
    sessionStorage.setItem(MY_SELECTED_TEAM_, team?.teamName as string);

    dispatch({
      type: actions.storeTeamDetails,
      payload: { currentTeam: team },
    });
    dispatch(history.push("/home"));

    return true;
  };

  useEffect(() => {
    toggleLoading(!isLoading);
  }, [userRoleState?.value?.teamId]);

  const [data, setData] = useState<Array<any | null>>([]);

  useEffect(() => {
    if (state?.value) {
      const values = state?.value?.values;
      setData(values);
    }
  }, [state?.value]);

  return (
    <>
      <Box className={teamClasses.container}>
        <Box width="auto">
          {state?.loading && (
            <div className="team-selection-loader">
              <Loader showBackDrop={true} />
            </div>
          )}
          <TeamListViewer
            open={open}
            toggle={toggle}
            data={data}
            setSelectedTeamName={setSelectedTeamName}
            handleChangeNew={handleChangeNew}
            refreshUserTeams={subscribe}
            userUuid={userRoleState?.value?.uuid as string}
          ></TeamListViewer>
          {state?.value && state?.value.count >= 1 ? (
            <Button
              variant="outlined"
              color="secondary"
              style={{
                display: "flex",
                alignItems: "center",
                height: "43px",
                width: "auto",
                borderRadius: "0px",
                justifyContent: "space-between", // Adjusted to space-between
                padding: "0px 15px 0px 44px", // Added padding to ensure proper spacing
              }}
              startIcon={
                <ModusIcon
                  name={"people_group"}
                  className={teamClasses.teamIcon}
                  style={{ marginRight: "41px" }}
                />
              }
              endIcon={<ExpandMoreIcon style={{ marginLeft: "20px" }} />}
              onClick={onClick}
            >
              {selectedTeamName}
            </Button>
          ) : (
            <Box className={teamClasses.initial}>
              <ModusIcon
                name={"people_group"}
                className={teamClasses.initialIcon}
              />
              {state?.loaded && (
                <Typography variant="body2" className="sector">
                  {props.group}
                </Typography>
              )}
              {state?.loading && (
                <CircularProgress
                  color="primary"
                  size="20px"
                  style={{ marginLeft: "15px" }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
