import React from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { TeamRoutes }  from './team/routes';

import {TeamRoutes as NewTeamRoutes}  from './teams/routes';
import ProxyRoutes from './proxy/routes';
import MarketplaceRoutes from './consumer/routes';
import SubscriptionRoutes from './subscriptions/routes';
import Profile from './profile/profile';
import ProductRoutes from './products/routes';
import { ResourceAbility } from 'libs/security/authorization';
import { UserNotExistsCheck } from 'libs/security/authorization/RoleName';
import IdentityApplicationRoute from './applications/routes';
import Starter from './Starter';
import NoAccessMessage from 'pages/login/NoAccessMessage';
import RegisterMessage from './RegisterMessage';
import CertificateRoutes from 'pages/home/truststores/routes';
import ApprovalRoutes from '../home/approval/routes';
import CertificateExpiryList from './truststores/CertificateExpiryList';
import ProductConsumerRoutes from './products/pages/Routes';
import NotificationRoutes from './notifications/NotificationRoutes';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>
/**
 * @class HomeRoutes
 * @description For storing internal page redirection inside Home Module
 */
export const HomeRoutes: React.FC = () => {

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
          return (
               <Switch>
                    <Route path="/home/proxy" render={() => (
                         <ResourceAbility can="read" resource={({ Proxy }) => Proxy.list} fallBack={fallBackdiv} >
                              <ProxyRoutes />
                         </ResourceAbility>
                    )} />

                    <Route path="/home/products" render={() => (
                         <ResourceAbility can="read" resource={({ Product }) => Product.list} fallBack={fallBackdiv} >
                              <ProductRoutes />
                         </ResourceAbility>
                    )} />

                    <Route path="/home/certificates" render={() => (
                         <CertificateRoutes />
                    )} />

                    <Route path="/home/approvals" render={() => (
                         <ResourceAbility can="read" resource={({ Product }) => Product.list} fallBack={fallBackdiv} >
                              <ApprovalRoutes />
                         </ResourceAbility>
                    )} />

                    <Route path="/home/profile" render={() => (<Profile />)} />
                    {(process.env.REACT_APP_USER_ONBOARDING_FLOW !== "true") && ( 
                    <Route path="/home/teams" render={() => (
                         <TeamRoutes />
                    )} />
                    )}
                    {(process.env.REACT_APP_USER_ONBOARDING_FLOW === "true") && ( 
                    <Route path="/home/teams" render={() => (
                         <NewTeamRoutes />
                    )} />
                    )}

                    <Route path="/home/marketplace" render={() => (
                         <MarketplaceRoutes />
                    )} />

                    <Route path="/home/consumer/products" render={() => (
                         <ProductConsumerRoutes />
                    )}  />

                    <Route path="/home/subscriptions" render={() => (
                         <SubscriptionRoutes />
                    )} />

                    <Route path="/home/applications" render={() => (<IdentityApplicationRoute />)} />

                    <Route path="/home/notifications" render={() => (<NotificationRoutes />)} />
                    
                    <Route exact path="/home/register" component={RegisterMessage} />
                    <Route exact path="/home/noaccess" component={NoAccessMessage} />

                    <Route exact path="/home/" render={() => (<Starter />)} />
                    
                    <Route path="/home/certificate/expiry" render={() => (
                    <CertificateExpiryList />
                     )} />
                    <UserNotExistsCheck />
               </Switch>
          );
}

export default withRouter(HomeRoutes);
