import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockIcon from "@material-ui/icons/Lock";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AddIcon from "@material-ui/icons/Add";
import {
  Avatar,
  Button,
  createStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ResourceServerFormDialog from "./ResourceServerFormDialog";
import ResourceApplicationForm from "./ResourceApplicationForm";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import ResourceScopeForm from "./ResourceScopeForm";
import { IdentityApplicationCredentialsView, useIdentityApplicationInfo } from "libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import { IdentityApplicationEnvironmentType } from "libs/resources/identity/applications/PublisherApplicationService";
import { IdentityApplicationViewContext } from "libs/resources/identity/applications/IdentityApplicationView";
import IdentityApplicationConfigurationsView from "libs/resources/identity/applications/application-views/IdentityApplicationConfigurationsView";
import { ResourceAbility } from "libs/security/authorization";

const useResourceAuthorizerStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    configurationPanel: {
      padding: spacing(2),
      textAlign: "center",
      width: "100%",
      minHeight: "120px",
    },
    section: {
      width: "100%"
    },
    noConfigMessage: {
      color: palette.text.secondary,
      paddingBottom: "15px",
    },
    scopeList: {
      maxHeight: '200px',
      overflowY: 'auto',
      marginBottom: '10px'
    }
  })
);

interface ScopeProps {
  scope: string,
  description: string
}
interface ResourceServerProps {
  resource_name?: string;
  gateway_name?: string;
  environment_name?: string;
  scopes?: Array<ScopeProps>;
  consumer_key: string;
  consumer_secret: string;
  grant_types: string[];
}
interface ResourceAuthorizerProps {
  resourceServer?: ResourceServerProps;
  apiName: string;
  gatewayName: string;
  onSuccess: () => void ;
}

const PROPERTIES = {
  APPLICATION_NAME: "APPLICATION_NAME",
  SCOPE: "SCOPE"
}
export const ResourceAuthorizer: React.FC<ResourceAuthorizerProps> = ({
  resourceServer, apiName, gatewayName, onSuccess
}) => {

  const classes = useResourceAuthorizerStyles();

  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);
  const [property, setProperty] = useState<string>("APPLICATION_NAME"); // APPLICATION_NAME | SCOPE
  const action = useRef<string>("CREATE");
  const [resourceServerName, setResourceServerName] = useState<string | undefined>(undefined);
  const [scopes, setScopes] = useState<ScopeProps[] | undefined>(undefined);
  const [consumerKey, setConsumerKey] = useState<string>("");
  const [consumerSecret, setConsumerSecret] = useState<string>("");

  const { state, dispatch } = useIdentityApplicationInfo({
    applicationName: resourceServer?.resource_name,
    environmentName: gatewayName as IdentityApplicationEnvironmentType
  });

const [viewContext, setViewContext] = useState({
    state,
    dispatch
});

useEffect(() => {
  resourceServer && setResourceServerName(resourceServer?.resource_name);
  resourceServer && resourceServer.scopes && setScopes(resourceServer?.scopes);
  resourceServer && updateResourceServerKeys(resourceServer);
    setViewContext({
        state,
        dispatch
    })
}, [state, dispatch, resourceServer]);


  const onOpenPopUp = (clickedAction: string, currentProperty: string) => {
    action.current = clickedAction;
    setProperty(currentProperty);
    setOpenDialog(true);
  };
  const onClosePopup = () => {
    setOpenDialog(false);
  };
  const updateResourceServerName = (resourceName: string) => {
    setResourceServerName(resourceName);
    setOpenDialog(false);
    onSuccess();
  }
  const updateResourceServerKeys = (resourceServer: ResourceServerProps) => {
    setConsumerKey(resourceServer.consumer_key);
    setConsumerSecret(resourceServer.consumer_secret);
  }

  const updateScopeList = (newScope: ScopeProps) => {
    let existingScopes = [];
    let isExists = false;
    scopes?.forEach((scope) => {
      if (newScope.scope === scope.scope) {
        existingScopes.push(newScope);
        isExists = true;
      } else {
        existingScopes.push(scope);
      }
    });

    if (!isExists) {
      existingScopes.push(newScope);
    }
    setScopes(existingScopes);
    setOpenDialog(false);
  }

  return (
    <div className="authz">
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionSummaryTitle>
          <Typography variant="body1">
              {resourceServer?.environment_name ? resourceServer?.environment_name + " Scope" : "Scope Prefix"}
            </Typography>
          </AccordionSummaryTitle>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          {resourceServerName && (
            <>
            <AccordionContent
              size="small"
              header={
                <FormattedMessage defaultMessage="Scope Information" />
              }
            >
              <FormViewInliner
                gutter="gutter-bottom"
                title={
                  <FormattedMessage defaultMessage="Scope Prefix" />
                }
              >
                <Typography variant="body1">
                  {resourceServerName}
                </Typography>
              </FormViewInliner>
              <FormViewInliner
                gutter="gutter-bottom"
                title={<FormattedMessage defaultMessage="Scopes" />}
              >
                <Typography variant="body1">
                  {(!scopes || scopes.length === 0) && (
                    <FormattedMessage defaultMessage="No Scopes added yet.." />
                  )}
                </Typography>
                <div className={classes.scopeList}>
                  {scopes && scopes?.map((scope, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <LockIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={scope.scope} secondary={scope.description} />
                    </ListItem>
                  ))}
                </div>

                <ListItem key={"add"}>
                <ResourceAbility can="create" resource={({ Product }) => Product.create}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      onOpenPopUp("CREATE", PROPERTIES.SCOPE);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {scopes && scopes.length > 0 ? (
                      <FormattedMessage defaultMessage="Add More Scopes" />
                    ) : (
                        <FormattedMessage defaultMessage="Add Scopes" />
                      )}
                  </Button>
                  </ResourceAbility>
                </ListItem>
              </FormViewInliner>
            </AccordionContent>
          </>
          )}
          {!resourceServerName && (
            <div className={classes.configurationPanel}>
              <div className={classes.noConfigMessage}>
                <Typography variant="caption">
                  <FormattedMessage defaultMessage="Scope not yet configured" />
                </Typography>
              </div>
              <div>
              <ResourceAbility can="create" resource={({ Product }) => Product.create}>
                <Button
                  startIcon={<SettingsOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onOpenPopUp("CREATE", PROPERTIES.APPLICATION_NAME);
                  }}
                >
                  <FormattedMessage defaultMessage="Configure" />
                </Button>
                </ResourceAbility>
              </div>
            </div>
          )}
          <ResourceServerFormDialog show={isOpenDialog} onClose={onClosePopup}>
            {property === PROPERTIES.APPLICATION_NAME &&
              (<ResourceApplicationForm
                onCancel={onClosePopup}
                onSuccess={updateResourceServerName}
                action={action.current}
                apiName={apiName}
                gatewayName={gatewayName}
              />)
            }
            {property === PROPERTIES.SCOPE &&
              (<ResourceScopeForm
                onCancel={onClosePopup}
                onSuccess={updateScopeList}
                action={action.current}
                apiName={apiName}
                gatewayName={gatewayName}
                resourceName={resourceServerName}
                isInitialScope={!scopes || (scopes?.length === 0)}
              />)
            }
          </ResourceServerFormDialog>
        </AccordionDetails>
        {resourceServerName && (
          <>
            <AccordionDetails className="accordionDetails">
              <AccordionContent size="small" header={
                  <FormattedMessage defaultMessage="Key Details" />}>
              <IdentityApplicationCredentialsView allowModify={true} applicationName={state.application?.applicationName} variant="inline" publishedGateway={
                {consumerKey: consumerKey, consumerSecret: [consumerSecret],
                organisationName: resourceServer?.gateway_name as IdentityApplicationEnvironmentType}}/>
            </AccordionContent>
            </AccordionDetails>
            <AccordionDetails className="accordionDetails">
              <div className={classes.section}>
                <IdentityApplicationViewContext.Provider value={viewContext}>
                    <IdentityApplicationConfigurationsView />
                </IdentityApplicationViewContext.Provider>
              </div>
            </AccordionDetails>
          </>
          )}
      </Accordion>
    </div>

  );
};

export default ResourceAuthorizer;


/**
 *
 * TODO: Add this when Edit feature is included
    controls={
      <IconButton
        size="small"
        edge="end"
        onClick={() => {
          onOpenPopUp("EDIT", PROPERTIES.APPLICATION_NAME);
        }}
      >
        <EditIcon />
      </IconButton>
    }
 */